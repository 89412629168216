import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import foodInitImg from '../../images/landings/food-aid/food-initiative.jpg';
import feedAnOrphanImg from '../../images/landings/food-aid/feed-orphan.jpg';
import sadaqahImg from '../../images/landings/food-aid/sadaqah-jariyah.jpg';
import buildWaterImg from '../../images/landings/food-aid/build-water.jpg';
import crisisInitiativeImg from '../../images/landings/food-aid/crisis-initiative.jpg';
import { selectProducts } from '../../store/products';
import banner from '../../images/landings/food-aid/food-and-water-hero-banner-desktop.jpg';
import bannerMobile from '../../images/landings/food-aid/food-and-water-hero-banner-mobile.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import { OurPromiseSection } from '../../components/general';
import FoodWaterTabs from './components/foodWaterTabs';
import ImagesSection from './components/images';
import FAQSection from './components/faq';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { impactSliderData } from './components/fixtures';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';

const FoodAndWaterLandingPage = ({lang='en'}) => {
  const bannerFr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Desktop-FR.webp';
  const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Mobile-FR.webp';
  const bannerAr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Desktop-AR.webp';
  const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Food-Water-Aid-Generic-Hero-Banner-Mobile-AR.webp';
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
  const [waterAidProducts, setWaterAidProducts] = useState([0, 0, 0]);
  const [babyMilkProducts, setBabyMilkProducts] = useState([0, 0, 0]);
  const [ourFoodProduct, setOurFoodProduct] = useState();
  const [waterCrisisProduct, setWaterCrisisProduct] = useState();
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempFoodAidProducts = [];
    let tempWaterAidProducts = [];
    let tempBabyMilkProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') console.log('ppp=',inner_item_product);
          if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') setOurFoodProduct(inner_item_product);
          if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') setWaterCrisisProduct(inner_item_product);
          // foodAidProducts
          // if (inner_item_product.creator === 'RM24-FA-009') tempFoodAidProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') tempFoodAidProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-DBD-2023-01-051') tempFoodAidProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') tempFoodAidProducts[2] = inner_item_product;

          // waterAidProducts
          if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') tempWaterAidProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') tempWaterAidProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'AO07-FWD-DIS-FAO-2023-01-062') tempWaterAidProducts[2] = inner_item_product;

          // Baby milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Baby Milk for 1 Week',
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Baby Milk for 4 Weeks',
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Baby Milk for 52 Weeks',
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempWaterAidProducts = tempWaterAidProducts.filter(n => n)
    tempBabyMilkProducts = tempBabyMilkProducts.filter(n => n)
    setFoodAidProducts(tempFoodAidProducts);
    setWaterAidProducts(tempWaterAidProducts);
    setBabyMilkProducts(tempBabyMilkProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['foodAndWaterAid']['foodAndWaterAid']['title']}`}</title>
          <meta name="title" content={`${seo['foodAndWaterAid']['foodAndWaterAid']['title']}`} />
          <meta name="description" content={`${seo['foodAndWaterAid']['foodAndWaterAid']['description']}`} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-GEN-MFA-2023-01-118',
            label: 'Food Aid',
            value: 103,
          }}
        />
      </section>

      <section className="flex justify-center">
      <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : banner} alt="Donate to Palestine" className="max-[500px]:hidden w-full" />
        <img
          src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : bannerMobile}
          alt="Donations to Palestine"
          className="min-[501px]:hidden w-full"
        />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-GEN-MFA-2023-01-118',
              label: 'Food Aid',
              value: 103,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <FoodWaterTabs
        babyMilkProducts={babyMilkProducts}
        foodAidProducts={foodAidProducts}
        waterAidProducts={waterAidProducts}
      />
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" />
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
        </div>
        <MaxWidthContainer className="!max-w-[1440px]">
          <div className="hidden sm:flex flex-wrap gap-8 justify-center items-center">
            {impactSliderData.map(({ img, value, title }, index) => (
              <div className="keen-slider__slide flex basis-1/8 md:basis-1/12 flex-col items-center gap-1" key={index}>
                <div className="flex rounded-full p-4 bg-white justify-center items-center w-[80px] h-[80px]">
                  <img src={img.src} alt={img.alt} className='' />
                </div>
                <div className="flex flex-col items-center text-center">
                  <h4 className="md:text-2xl font-brandingBold text-[#253B7E]">{value}</h4>
                  <small className="text-xs md:text-base font-brandingMedium uppercase text-[#78716C]">{title}</small>
                </div>
              </div>
            ))}
          </div>
        </MaxWidthContainer>
        <MaxWidthContainer className="sm:hidden !max-w-[1440px]">
          <div className="relative">
            <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
              {impactSliderData.map(({ img, value, title }, index) => (
                <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                  <img src={img.src} alt={img.alt} />
                  <div className="flex flex-col items-center text-center">
                    <h4 className="md:text-2xl font-brandingBold text-[#253B7E]">{value}</h4>
                    <small className="text-xs md:text-base font-brandingMedium uppercase text-[#78716C]">{title}</small>
                  </div>
                </div>
              ))}
            </div>
            {loadedImpact && instanceRefImpactSlider.current && (
              <>
                <Arrow
                  left
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                  className="bg-transparent"
                  disabled={currentImpactSlide === 0}
                  icon={impactSliderLeftIcon}
                />

                <Arrow
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                  disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                  className="bg-transparent"
                  icon={impactSliderRightIcon}
                  rightMargin
                />
              </>
            )}
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          Stand with MATW in<span className="text-[#F60362] ml-1">ending poverty.</span>
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>
            Food and clean water. These are among life’s most basic necessities and yet there
            are more than 820 million people going to bed hungry every night, and more than 2
            billion who lack access to safe drinking water.
            <br />
            <br />
            Imagine living each day uncertain about where your next meal will come from. Or whether
            the water you give your children will make them sick.
            <br />
            <br />
            This is the reality for millions living in impoverished communities around the world -
            especially those suffering from the effects of conflict and natural disasters.
            <br />
            <br />
            Our Food and Water Crisis fund is dedicated to ensuring access to clean water and delivering
            urgent food to the most vulnerable in the most difficult-to-reach areas. With your continued
            support, we’re not only able to provide urgent relief, we’re able to provide hope.
          </p>
        </div>
        <div className="relative overflow-hidden flex flex-wrap md:py-16 mb-8 md:mb-16">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/IgENJBleHK4?controls=0&autoplay=1&loop=1&mute=1&playlist=IgENJBleHK4`} className={'md:!h-[800px] lg:!h-[1000px]'} />
        </div>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] md:leading-[48px] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          More than
          <span className="text-[#F60362] mx-1">828 million</span>
          people sleep hungry.
          <span className="text-[#F60362] mx-1">You can help change that.</span>
          Help us reach more now.
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>
            Whether it be people in countries riddled with conflict, families in disaster-prone areas, or
            children in impoverished communities, hunger doesn’t differentiate. It kills. Your Sadaqah
            could be the difference between life and death for an orphan in Palestine, a family in
            Lebanon, or a widow in Pakistan. As the cost of essential food supplies continues to soar
            across the globe, our ummah needs to help ensure their survival.
            <br />
            <br />
            Our MATW team is working tirelessly in more than 30 countries around the world to reach those
            most in need. Help us ensure that the most vulnerable have what they need to get them. Be the
            answer to their duas. Help us help them survive.
          </p>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------------ Section Three - Food AID ------------------------ */}
      <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0">
        <div className="basis-full md:basis-1/3 flex justify-center items-center">
          <img src={foodInitImg} alt="" className='w-full- max-h-[550px] rounded-lg' />
        </div>
        <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
          <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Our Food Initiative: Ending Hunger Together
          </h1>
          <p className='text-lg md:text-xl'>
            With our Food Crisis initiatives, you hold the power to create meaningful change. Donate now to provide vital assistance, including crucial items like baby milk, rice bags, bread, and food packs to those in urgent need. Every contribution, no matter how small, can make a significant impact. Discover more about the key items we urgently require and join us in our mission to alleviate hunger and nourish lives.
          </p>
          <CustomButton
            onClick={() => { ourFoodProduct && addProduct({product: ourFoodProduct, currency: selectedCurrencyStore || 'AUD'}) }}
            title={'Donate Now'}
            className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
          />
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ------------------------ Section Four - Water AID ------------------------ */}
      <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0">
        <div className="basis-full md:basis-1/3 flex justify-center items-center">
          <img src={feedAnOrphanImg} alt="" className='w-full- max-h-[550px] rounded-lg' />
        </div>
        <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
          <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Feed An Orphan
          </h1>
          <p className='text-lg md:text-xl'>
            When disaster & conflict strike, vulnerable children and orphans are the first to suffer. At least 40 million children around the world are severely food insecure. Help us provide nutritious meals for them every day.
          </p>
          <CustomButton
            link={'/orphans/orphan-feast'}
            title={'Feed an Oprhan'}
            className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
          />
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <div className="relative overflow-hidden flex flex-wrap md:py-16 mb-8 md:mb-16">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/vYkDk2J4068?controls=0&autoplay=1&loop=1&mute=1&playlist=vYkDk2J4068`} className={'md:!h-[800px] lg:!h-[1000px]'} />
        </div>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center font-brandingBold`}>
          Last year, our MATW team delivered more than of <span className="text-[#F60362] mx-1">30,000,000L</span>safe and clean drinking water.
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 font-brandingMedium`}>
          <p>
            From disaster zones to regions gripped in the midst of conflict, our MATW team has worked tirelessly to provide clean drinking water in form of filtration units, rainwater harvesting systems, water tanks, bottles and more. Clean water is a basic human right and we won’t stop until we reach as many people as possible.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0">
        <div className="basis-full md:basis-1/3 flex justify-center items-center">
          <img src={crisisInitiativeImg} alt="" className='w-full- max-h-[550px] rounded-lg' />
        </div>
        <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
          <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Our Water Crisis Initiative: Empower Lives with Clean Water
          </h1>
          <p className='text-lg md:text-xl'>
            Your donation can provide essential assistance through the installation of water wells and the establishment of water distribution systems. Even the tiniest ripples make a significant difference. Explore the critical resources we urgently require and be part of the wave of change that brings hope and relief to communities in need. Together, let's create a sustainable future with clean water as its foundation.
          </p>
          <CustomButton
            onClick={() => { waterCrisisProduct && addProduct({product: waterCrisisProduct, currency: selectedCurrencyStore || 'AUD'}) }}
            title={'Donate Now'}
            className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0">
        <div className="basis-full md:basis-1/3 flex justify-center items-center">
          <img src={buildWaterImg} alt="" className='w-full- max-h-[550px] rounded-lg' />
        </div>
        <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
          <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Build A Water Well
          </h1>
          <p className='text-lg md:text-xl'>
            When you donate to build a water wells , not only does it act as a Sadaqah Jariyah but you’re also helping impoverished communities facing water scarcity, develop and grow.
          </p>
          <CustomButton
            link={'/sadaqah-jariyah/build-a-water-well'}
            title={'Build A Well'}
            className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mt-8 mb-8 md:mb-0">
        <div className="basis-full md:basis-1/3 flex justify-center items-center">
          <img src={sadaqahImg} alt="" className='w-full- max-h-[550px] rounded-lg' />
        </div>
        <div className="basis-full md:basis-2/3 flex flex-col justify-around items-start md:pl-8">
          <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Sadaqah Jariyah
          </h1>
          <p className='text-lg md:text-xl'>
            Sadaqah Jariyah is a charity that continues to earn the rewards, even after the giver has passed away. When you give Sadaqah Jariyah, it will support a project of lasting benefit both for the communities and for the giver. Ali Banat’s legacy started with three Sadaqah Jariyah projects, and thus MATW was born.
            <br />
            <br />
            What will YOUR Sadaqah Jariyah and legacy be?
          </p>
          <CustomButton
            link={'/sadaqah-jariyah'}
            title={'Donate Now'}
            className="text-white flex items-center justify-center text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
          />
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <FAQSection />
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default FoodAndWaterLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${props.bg ? props.bg : 'bg-[#F60362]'
        } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.className ? props.className : ''
        } ${props.left ? 'arrow--left left-[0px]' : 'arrow--right left-auto right-[0px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? <img alt='arrow-img' src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-3' : '-ml-3'} `} />
        : (
          <svg
            className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
            {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
          </svg>
        )}
    </div>
  );
}