import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import Layout from '../../components/general/layout';
import { selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import services from '../../services';
import DetailsWidget from './detailsWidget';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { howItWorksItems } from '../crisis-and-emergencies/palestineRefugeeSettlement/data';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';

const DetailsPalestineProduct = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [product, setProduct] = useState();
    const [productTitle, setProductTitle] = useState('');
    const [isFromOut, setIsFromOut] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('AUD');
    // const pixelID = localStorage.getItem('TikTokID')
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleParagraph = (text) => {
        let tempText = [];
        if (text) {
            tempText = text.split("\n");
            return tempText.map((txt, index) => {
                let temp = txt === "" ? '' : <p key={`p${index}`}>{txt}.<br /></p>
                return temp;
            })
        }
    }
    const getProduct = async (code, currency) => {
        setIsLoaded(false);
        // const temp = { product: code, currency: currency }
        // const temp = { name: 'Palestine Emergency Medical Kit', currency: currency }
        const temp = { name: code, currency: currency }
        // console.log("temp", temp)
        // setIsDisabledNextBtn(true);
        // setSelectedCurrency(currency)
        try {
            const response = await services.getProduct(temp);
            setProduct(response.data);
            console.log("response nmame", response.data)
            console.log("response nmame", response.data)
            response.data.name === 'Zakat Al Fitr'
                ? setProductTitle('Zakat Al Fitr | Zakat Al Fitr 2024')
                : setProductTitle(response.data.name)
            handleViewItemDataLayer(response.data, currency)
            handleCustomProductAmount(response.data)
            setIsLoaded(true);
        } catch (error) {
            console.log("error", error)
            navigate('/')
            setIsLoaded(true);
        }
    }
    const handleCustomProductAmount = (product) => {
        product.unit_amount > 4
            ? setIsAnyAmount(false)
            : setIsAnyAmount(true)
    }
    const handleBackBtn = () => {
        isFromOut ? navigate('/') : navigate(-1)
    }
    const handleViewItemDataLayer = (tempProduct, currency) => {
        // console.log("datalayer view item", tempProduct)
        let dataLayerTemp = {
            event: 'view_item',
            ecommerce: {
                currency: currency.toUpperCase(),
                items: [{
                    item_id: tempProduct.creator,
                    item_name: tempProduct.name,
                    item_brand: tempProduct.category ? tempProduct.category : "",
                    item_category: tempProduct.category ? tempProduct.category : "",
                    item_description: tempProduct.description || '',
                    price: tempProduct[currency.toLowerCase()],
                }]
            }
        }
        window.dataLayer = window.dataLayer || [];
        IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
        let obj = {
            content_type: 'product',
            content_id: tempProduct.creator,
            currency: currency.toUpperCase(),
            value: tempProduct[currency.toLowerCase()],
            price: tempProduct[currency.toLowerCase()],
            quantity: 1,
            content_category: tempProduct.category ? tempProduct.category : 'donation',
            content_name: tempProduct.name,
            description: tempProduct.description,
        }
        window.ttq && window.ttq.track('ViewContent', {
            ...obj
        });
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        window.history.state.idx ? setIsFromOut(false) : setIsFromOut(true);
        console.log("location", location, selectedCurrency)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.history]);
    useEffect(() => {
        let pathNames = window.location.pathname.split('/');
        if (selectedCurrencyStore) {
            setSelectedCurrency(selectedCurrencyStore);
            pathNames.length > 1 && getProduct(pathNames[3].split("-").join(" ").toLowerCase(), selectedCurrencyStore);
        } else {
            setSelectedCurrency('AUD');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    /* -------------------------------------------------------------------------- */
    return (
        <Layout>
            <Helmet>
                {/* <title>{`${product ? product.name : ''} - MATW Project`}</title> */}
                {/* <meta name="title" content={`${product ? product.name === 'Zakat Al Fitr' ? 'Zakat Al Fitr - Zakat Al Fitr 2024' : product.name : ''} - MATW Project`} /> */}
                <title>{`${productTitle} | MATW Project`}</title>
                <meta name="title" content={`${productTitle} | MATW Project`} />
                <meta name="description" content={`${product ? product.description : ''}`} />
                <div itemscope itemtype="http://schema.org/Product">
                    <meta itemprop="brand" content="MATW-Project" />
                    <meta itemprop="name" content={`${product ? product.name : ''} - MATW Project`} />
                    <meta itemprop="description" content={`${product ? product.description : ''}`} />
                    <meta itemprop="productID" content={`${product ? product.creator : ''}`} />
                    <meta itemprop="url" content={window.location.href} />
                    <meta itemprop="image" content={product ? product.image_link : ''} />
                    <meta itemprop="price" content={product ? product.unit_amount : '100'} />
                </div>
            </Helmet>
            <div className="w-full md:container md:mx-auto px-4 md:px-0">
                <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] py-2 md:pt-8 font-['Gotcha'] my-6">{product ? product.name : <Skeleton height={40} />}</h1>
                <div className="w-full flex flex-wrap p-4 md:p-8 rounded-xl shadow-lg bg-white">
                    <div className="overflow-hidden rounded-md basis-full md:basis-1/4 [&>span]:!block">
                        {product ? product.image_link
                            ? <LazyLoadImage
                                alt={product.name}
                                effect="blur"
                                width={`100%`}
                                delayTime={500}
                                className='rounded'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                src={product.image_link} // use normal <img> attributes as props
                            />
                            : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                            : <Skeleton height={344} />
                        }
                    </div>
                    <div className="flex flex-col justify-between basis-full md:basis-3/4 px-0 md:px-4">
                        <div className="flex flex-col">
                            <div className='text-[#f60362] flex items-center flex-col md:flex-row'>
                                <div
                                    className="flex transition-all duration-200 bg-[#00a3da] hover:bg-[#253b7e] rounded-full w-[35px] h-[35px] cursor-pointer mt-2 md:mt-0 justify-center items-center"
                                    onClick={() => handleBackBtn()}
                                >
                                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.237 6.19242H2.26559L6.97598 1.34241C7.11929 1.19478 7.19972 0.994622 7.19956 0.785998C7.1994 0.577374 7.11867 0.377351 6.97513 0.229947C6.90405 0.156961 6.8197 0.0990874 6.72688 0.0596312C6.63407 0.020175 6.53459 -8.0904e-05 6.43416 2.42848e-07C6.23132 0.000164126 6.03686 0.083187 5.89354 0.230822L0.469458 5.80965C0.168735 6.1216 0 6.5431 0 6.98239C0 7.42168 0.168735 7.8432 0.469458 8.15514L5.87824 13.748C5.94802 13.8254 6.03225 13.8877 6.12592 13.9309C6.21958 13.9742 6.32076 13.9976 6.42341 13.9998C6.52607 14.002 6.62811 13.9829 6.72343 13.9437C6.81876 13.9044 6.90543 13.8458 6.97826 13.7714C7.05109 13.6969 7.1086 13.6082 7.14736 13.5104C7.18612 13.4126 7.20533 13.3078 7.20385 13.2022C7.20237 13.0966 7.18022 12.9924 7.13874 12.8958C7.09725 12.7991 7.03727 12.7121 6.96238 12.6399L2.25029 7.76365H16.2353C16.4381 7.76365 16.6326 7.68077 16.776 7.53328C16.9194 7.38578 17 7.18575 17 6.97716C17 6.76857 16.9194 6.56852 16.776 6.42103C16.6326 6.27353 16.4381 6.19067 16.2353 6.19067L16.237 6.19242Z" fill="#fff" />
                                    </svg>
                                </div>
                                <h3 className="text-[#00a3da] ml-2 flex items-center md:text-lg xl:text-2xl text-center- md:text-left font-medium my-2 leading-4 md:leading-none">
                                    {product ? product.name : <Skeleton height={56} />}
                                </h3>
                            </div>
                            <div className="relative">
                                {product ? <>
                                    <div className={`text-center- md:text-left my-2 relative mb-4`}>
                                        <p className={`text-stone-400 text-xs sm:text-sm md:text-base lg:text-md overflow-hidden transition-all duration-200`}>
                                            {handleParagraph(product.description)}
                                        </p>
                                    </div>
                                </> : <Skeleton count={4} />}
                            </div>
                        </div>
                        {product && <DetailsWidget defaultProduct={product} isLoaded={isLoaded} isAnyAmount={isAnyAmount} />}
                    </div>
                </div>
            </div>
            <div className="w-full px-4 md:px-0 bg-[#253b7e] py-4 mt-8 md:mt-16">
                <div className="w-full md:container md:mx-auto bg-[#253b7e] py-4">
                    <h2 className="text-White mb-3 text-[25px] md:text-[40px] font-montserratBold text-center text-white">How it works:</h2>
                    <div className="grid grid-cols-4 gap-2 md:gap-4 my-6 md:my-10 px-2 md:px-4">
                        {howItWorksItems.map((item, index) => {
                            return (
                                <div
                                    key={`how_${index}`}
                                    className="col-span-2 lg:col-span-1 bg-white rounded-2xl p-2 md:p-4 flex flex-col justify-center items-center"
                                >
                                    <img src={item.icon} alt={item.title} className="mb-2" />
                                    <p className="text-[#253B7E] text-center mb-2 md:text-lg font-montserratBold">{item.title}</p>
                                    <p className="text-[#78716C] text-sm md:text-base font-montserratMedium">{item.description}</p>
                                </div>
                            );
                        })}
                    </div>
                    <p className="text-white text-center md:text-2xl w-full md:w-2/3 md:mx-auto">
                        Help us continue supporting Palestinian refugee families as they begin to arrive in Egypt to begin the next chapter of their lives and rebuild.
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default DetailsPalestineProduct;
