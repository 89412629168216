import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ReactSelect from 'react-select';
import creditImg from '../../images/payment/credit-cards.png';
import SelectCurrency from '../general/selectCurrency';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import CustomButton from '../general/button';

const styles = {
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const WidgetHome = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { selectedCurrency, className, defaultValue = 150 } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [productAmount, setProductAmount] = useState(defaultValue);

  const [currentProduct, setCurrentProduct] = useState({
    creator: 'MKD-MN-001',
    label: 'Where Most Needed',
    value: 122,
  });
  /* -------------------------------------------------------------------------- */
  // const handleAmountChange = e => {
  //   const newValue = e.target.value;
  //   const regex = /^(?:[1-9]\d*)?$/;

  //   if (regex.test(newValue)) {
  //     setProductAmount(newValue);
  //   }
  // };
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Earthquake Appeal') {
            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Morocco Appeal
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[2] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Libya Floods') {
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Libya Floods
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[3] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Palestine') {
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Palestine Appeal
              // setSelectedProduct(inner_item_product);
              // setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
              tempImportantProducts[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
              tempMakeDonationProducts.push(inner_item_product);
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
              // Palestine Medical Relief
              tempMakeDonationProducts.push(inner_item_product);
              tempImportantProducts[1] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator };
            }
          }
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator === 'MKD-MN-001') {
              // Where Most Needed
              setSelectedProduct(inner_item_product);
              setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);
    tempImportantProducts = tempImportantProducts.filter(n => n)
    setProductsList(tempImportantProducts);
    setAllProducts(tempMakeDonationProducts);
  };
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    selectedCurrency(currency);
  };
  const handleProduct = value => {
    allProducts.map(item => item.id === value.value && setSelectedProduct(item));
    setCurrentProduct(value);
  };
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      //   recurring: selectedRecurring.value,
    });
    console.log('before send', tempProduct);
    addProduct({product: tempProduct, currency: currentCurrency})
  };
  // const handleScroll = () => {
  //     const position = window.pageYOffset;
  //     setScrollPosition(position);
  // };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProducts();
    // window.addEventListener('scroll', handleScroll, { passive: true });
    // return () => {
    //     window.removeEventListener('scroll', handleScroll);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    // <div className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} ${scrollPosition > 300 ? 'fixed top-0 md:top-[64px] lg:top-[96px] w-full z-30' : 'relative'}`}>
    <div
      className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} sticky top-16 md:top-[64px] lg:top-[96px] w-full z-30`}
    >
      <div className="w-full md:container md:mx-auto ">
        <div className="grid grid-cols-2 lg:grid-cols-[1.5fr_1fr_1fr_1fr] gap-2 lg:gap-4 max-w-[1000px] mx-auto">
          <div className="flex items-center bg-white rounded-md">
            <div className="min-w-max">
              <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select" />
            </div>
            <input
              value={productAmount}
              className="text-[#777] w-full truncate p-2 rounded-lg text-sm pl-1 md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              // onChange={e => {
              //     setProductAmount(e.target.value);
              //     // setSelectedPrice(null);
              // }}
              min={'0'}
              onChange={e => {
                const regex = /^(?:[1-9]\d*)?$/;
                if (regex.test(e.target.value)) {
                  setProductAmount(e.target.value);
                }
              }}
              placeholder="Amount"
            />
          </div>

          <div>
            <ReactSelect
              options={productsList}
              className="max-h-[48px] [&>div]:!rounded-md"
              classNamePrefix="home-select"
              defaultValue={currentProduct}
              isSearchable={false}
              styles={styles}
              onChange={e => handleProduct(e)}
            />
          </div>

          <div className="rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center">
            <img
              src={creditImg}
              alt="MATW"
              className="w-full h-auto w-[260px]- h-[20px]- sm:w-[254px]- sm:h-[28px]- md:w-[221px]- md:h-[25px]- lg:w-[125px]- lg:h-[14px]- xl:w-full- xl:h-auto-"
            />
          </div>

          <div>
            <CustomButton
              onClick={() => {
                handleAddNewProduct();
              }}
              title={'Quick Donate'}
              disabled={!isLoaded}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 ${isLoaded ? 'blob red' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetHome;
