import React from 'react'
import Accordion from '../../../../components/general/accordion';

const FAQSectionOtherPalestinePage = ({ FAQs, lang = 'en' }) => {
  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className={`text-[#253B7E] text-left sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
        {lang === 'ar'
          ? <><span className="text-[#F60362]">الأسئلة</span> الشائعة حول تبرع لفلسطين: اكتشف كيف يغذي دعمك مبادراتنا الخيرية في فلسطين</>
          : lang === 'fr'
            ? <><span className="text-[#F60362]">FAQ</span>sur le don de Palestine</>
            : <>Palestine Donations <span className="text-[#F60362]"> FAQ’s</span></>
        }
      </h2>
      <div className="grid grid-cols-3 gap-x-16 gap-y-8">
        <div className="col-span-3 sm:col-span-1">
          <p className={`text-[#253B7E] text-lg md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>{FAQs[0].question}</p>
          <p className={`text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{FAQs[0].answer}</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className={`text-[#253B7E] text-lg md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>{FAQs[1].question}</p>
          <p className={`text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{FAQs[1].answer}</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className={`text-[#253B7E] text-lg md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>{FAQs[2].question}</p>
          <p className={`text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{FAQs[2].answer}</p>
        </div>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
        {FAQs.map((item, index) => {
          return (
            index > 2 && (
              <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                <Accordion
                  key={`keyallfaq${index}`}
                  shadow
                  title={item.question}
                  index={index}
                  lang={lang}
                  level={1}
                  noBorder={true}
                  className="!text-[#253B7E] text-lg !font-bold"
                >
                  {item.answer}
                </Accordion>
              </div>
            )
          );
        })}
      </div>
    </section>
  )
}

export default FAQSectionOtherPalestinePage;