import axios from 'axios';
const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_API_HEADER;

let config = {
  headers: {
    'X-user-matw': Header,
  },
};
// const instance = axios.create({
//   baseURL: Base,
//   headers: {
//     "X-user-matw": Header
//   }
// });
function allProducts() {
  // return instance.get('/checkout/stripe/all-products');
  return axios.get(`${Base}/checkout/stripe/all-products`, config);
}
function searchProducts() {
  return axios.get(`${Base}/checkout/stripe/distinct-products`, config);
}
function topProducts() {
  return axios.get(`${Base}/checkout/stripe/top-products`, config);
}
function mostPopularQurbanProducts() {
  return axios.get(`${Base}/checkout/stripe/most-popular-qurban-products`, config);
}
function qurban2023Products() {
  return axios.get(`${Base}/checkout/stripe/qurban-products`, config);
}
function fundraiserProducts() {
  return axios.get(`${Base}/checkout/stripe/fundraiser-products`, config);
}
function categoryProducts(id) {
  return axios.post(`${Base}/checkout/category/products`, { id: id }, config);
}
function categoryProductsByName(categoryName) {
  return axios.post(`${Base}/checkout/category/category-products`, { name: categoryName }, config);
}
function mainCategory() {
  return axios.get(`${Base}/checkout/category/main-categories`, config);
}
function subCategoryByName(categoryName) {
  return axios.post(`${Base}/checkout/category/sub-categories-by-main-category-name`, { name: categoryName }, config);
}
function subCategory(id) {
  return axios.post(`${Base}/checkout/category/sub-categories`, { id: id }, config);
}
function orphanProductTransactions() {
  return axios.post(`${Base}/checkout/reports/orphan-product-transaction-total`, '', config);
}
function convertCurrency(amount) {
  return axios.get(`${Base}/convert-currency?amount=${amount}`, config);
}

function getMostPopularProducts(tempSearch) {
  return axios.post(`${Base}/checkout/stripe/top-sale-products`, tempSearch);
}

function getAllProducts() {
  return axios.get(`${Base}/products`, config);
}
function getAddOnProducts() {
  return axios.get(`${Base}/products?is_addon=1`, config);
}

function exportToCSV() {
  const headers = {
    Authorization: "k76Uz2qZCbkwsAsmZWXZ9LsMJXNJEb"
  }
  return axios.post("https://backend.matwproject.org/api/products/export", null, { headers });
}
function getLatestDonors(tempSearch) {
  // return axios.post(`${Base}/latest-donors`, tempSearch);
  return axios.post(`https://backend.matwproject.org/api/latest-donors`, tempSearch);
}
function getLatestOrders(tempSearch) {
  // return axios.post(`${Base}/latest-orders`, tempSearch);
  return axios.post(`https://backend.matwproject.org/api/latest-orders`, tempSearch);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getMostPopularProducts,
  getAllProducts,
  allProducts,
  // allProducts,
  categoryProducts,
  mainCategory,
  subCategory,
  categoryProductsByName,
  subCategoryByName,
  topProducts,
  searchProducts,
  mostPopularQurbanProducts,
  qurban2023Products,
  orphanProductTransactions,
  fundraiserProducts,
  convertCurrency,
  getAddOnProducts,
  exportToCSV,
  getLatestDonors,
  getLatestOrders
};
