import Palestinepreramadan1 from '../../images/landings/palestine-refugee/palestine-refugee-slider-1.jpg';
import Palestinepreramadan2 from '../../images/landings/palestine-refugee/palestine-refugee-slider-2.jpg';
import Palestinepreramadan3 from '../../images/landings/palestine-refugee/palestine-refugee-slider-3.jpg';
import Palestinepreramadan4 from '../../images/landings/palestine-refugee/palestine-refugee-slider-4.jpg';
import Palestinepreramadan5 from '../../images/landings/palestine-refugee/palestine-refugee-slider-5.jpg';
import Palestinepreramadan6 from '../../images/landings/palestine-refugee/palestine-refugee-slider-6.jpg';
import Palestinepreramadan7 from '../../images/landings/palestine-refugee/palestine-refugee-slider-7.jpg';
import Palestinepreramadan8 from '../../images/landings/palestine-refugee/palestine-refugee-slider-8.jpg';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import { selectProducts } from '../../store/products';
import AppealDetailsTabs from './components/appealDetailTabs';
import AppealLevelGiving from './components/appealLevelGiving';
import AppealDonors from './components/appealDonors';
import AppealImpact from './components/appealImpact';
import AppealWidget from './components/appealWidget';
import services from '../../services';
import Layout from '../../components/general/layout';
// import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
// import AppealVideos from './components/appealVideos';
import AppealCurrentProduct from './components/appealCurrentProduct';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CarouselImages } from '../../components/general/imagesCarousel';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
// import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';

const MATWAppealDetailsLandingPage = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const impactRef = useRef(null);
  // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const navigate = useNavigate();
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [productTitle, setProductTitle] = useState('');
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [goal] = useState(500000);
  const [currentProduct, setCurrentProduct] = useState();
  const [donors, setDonors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [appealProducts, setAppealProducts] = useState([0, 0]);
  // const [orderProducts, setOrderProducts] = useState([]);

  const givingLevelProductsCodes = [
    'MKD-MN-001',
    'FWD-DIS-WPV-2023-01-054',
    'EMR-DIS-PSE-2023-02',
    'EMR-DIS-MDPL-2023-01-102',
    'EMR-DIS-BFPL-2023-01-202',
    'EMR-DIS-BFPL-2023-01-203',
    'EMR-DIS-BFPL-2024-01-207',
    'EMR-DIS-AA-2024-01-207',
    'EMR-DIS-PSE-2023-01',
    'EMR-DIS-BFPL-2023-01-204',
    'EMR-GEN-RHP-2023-01-131',
    'EMR-DIS-PSE-2023-03',
    'EMR-DIS-PSE-2023-04',
    'EMR-DIS-PSE-2023-05',
    'EMR-DIS-PSE-2023-06',
    'EMR-DIS-PSE-2023-01-01',
    'EMR-DIS-BFPL-2023-01-102',
    'EMR-DIS-MDPL-2023-01-102',
  ];
  const carouselImages = [
    { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
    { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
    { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
    { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
    { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan6, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan7, alt: 'donate for palestine emergency appeal' },
    { src: Palestinepreramadan8, alt: 'donate for palestine emergency appeal' },
  ];
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = _prds => {
    // setIsLoaded(true);
    if (products && Object.keys(products).length) {
      let tempGivingProducts = [];
      let tempAppealProducts = [];
      products.map(item => {
        item.sub_categories.map(inner_item => {
          if (inner_item.id === 65) { // Palestine Emergency
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  // console.log("on _prds=",_prdItem,inner_item_product)
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                  if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
                    campaign === 'palestine' && setCurrentProduct(inner_item_product);
                    campaign === 'palestine' && setProductTitle(inner_item_product.name);
                    tempAppealProducts[0] = inner_item_product;
                  }
                }
                return _prdItem;
              });
              campaign === 'palestine' && tempGivingProducts.push(inner_item_product);
              if (!tempAppealProducts[0] && inner_item_product.creator === 'MA-PAL-GL-1-001') {
                campaign === 'palestine' && setCurrentProduct(inner_item_product);
                campaign === 'palestine' && setProductTitle(inner_item_product.name);
                tempAppealProducts[0] = inner_item_product;
              }
              return inner_item_product;
            });
          }
          if (inner_item.id === 66) { // Palestine Refugee Resettlement
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                  if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
                    console.log("pros=",inner_item_product)
                    campaign === 'egyptian' && setCurrentProduct(inner_item_product);
                    campaign === 'egyptian' && setProductTitle(inner_item_product.name);
                    tempAppealProducts[1] = inner_item_product;
                  }
                }
                return _prdItem;
              });
              campaign === 'egyptian' && tempGivingProducts.push(inner_item_product);
              if (!tempAppealProducts[1] && inner_item_product.creator === 'MA-PAL-GL-3-001') {
                campaign === 'egyptian' && setCurrentProduct(inner_item_product);
                campaign === 'egyptian' && setProductTitle(inner_item_product.name);
                tempAppealProducts[1] = inner_item_product;
              }
              return inner_item_product;
            });
          }
          return inner_item;
        });
        return item;
      });
      tempAppealProducts = tempAppealProducts.filter(n => n);
      tempGivingProducts = tempGivingProducts.filter(n => n);
      console.log('set-AppealProducts=', tempAppealProducts);
      setGivingProducts(tempGivingProducts);
      setAppealProducts(tempAppealProducts);
    } else {
      getLiveAllProducts(_prds);
    }
  };
  const getLiveAllProducts = async _prds => {
    // setIsLoaded(true);
    let tempGivingProducts = [];
    let tempAppealProducts = [];
    let pathName = window.location.pathname.split('/')[3].split('-').join(' ').toLowerCase();
    console.log('getLiveAllProducts=', _prds);
    let response = await services.allProducts();
    response.data.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          _prds.map((_prdItem, _prdIndex) => {
            if (_prdItem.product_id === inner_item_product.id) {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: _prdItem.total_count,
                total_sale: _prdItem.total_sale,
                goal: 500000,
              });
              if (inner_item_product.name.toLowerCase() === pathName) {
                setCurrentProduct(inner_item_product);
                setProductTitle(inner_item_product.name);
              }
              appealProductsCodes.map(_code => {
                if (_code === inner_item_product.creator) {
                  tempAppealProducts.push(inner_item_product);
                }
                return _code;
              });
              givingLevelProductsCodes.map(_code => {
                if (_code === inner_item_product.creator) {
                  tempGivingProducts.push(inner_item_product);
                }
                return _code;
              });
            }
            return _prdItem;
          });
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempAppealProducts = tempAppealProducts.filter(n => n);
    tempGivingProducts = tempGivingProducts.filter(n => n);
    // console.log("set-AppealProducts=", tempGivingProducts2)
    setGivingProducts(tempGivingProducts);
    setAppealProducts(tempAppealProducts);
  };
  const getDonors = async _currency => {
    // let pathName = window.location.pathname.split('/')[3].split("-").join(" ").toLowerCase();
    let req = {
      products: [
        // 'RM24-FA-011',
        // 'RM24-ZM-013',
        // 'MKD-WEL-SDQ-2023-01',
        // 'WWC-CON-ABW-2023-02-028',
        // 'WWC-CON-L1W-2023-01-026',
      ],
      limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      // start_date: new Date()
    };
    campaign === 'palestine'
      ? (req.products = [
        'MA-PAL-GL-1-001',
        'MA-PAL-GL-1-002',
        'MA-PAL-GL-1-003',
        'MA-PAL-GL-1-004',
        'MA-PAL-GL-1-005',
        'MA-PAL-GL-1-006',
        'MA-PAL-GL-1-007',
        'MA-PAL-GL-1-008',
        'MA-PAL-GL-1-009',
      ])
      : (req.products = ['MA-PAL-GL-3-001', 'MA-PAL-GL-3-002', 'MA-PAL-GL-3-003', 'MA-PAL-GL-3-004', 'MA-PAL-GL-3-005', 'MA-PAL-GL-3-006']);
    try {
      const response = await services.getLatestDonors(req);
      setDonors(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getOrders = async _currency => {
    let req = {
      products: appealProductsCodes,
      // limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      // start_date: '2024-02-23'
    };
    try {
      const response = await services.getLatestOrders(req);
      // console.log('get orders=', products, response.data);
      // setAppealProducts(response.data)
      getAllProducts(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log("pathname==",window.location.pathname,isLoaded, selectedCurrencyStore, products)
    // if (selectedCurrencyStore && products && Object.keys(products).length && !isLoaded) {
    if (selectedCurrencyStore && products && Object.keys(products).length) {
      setCurrentProduct(null);
      getDonors(selectedCurrencyStore);
      getOrders(selectedCurrencyStore);
    }
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, products, navigate]);
  // useEffect(() => {
  //   console.log("pathname==",window.location.pathname.split('/')[3])
  //   if (selectedCurrencyStore && products && Object.keys(products).length) {
  //     console.log("on if pathname==",window.location.pathname)
  //     // setCurrentProduct(null)
  //     getDonors(selectedCurrencyStore);
  //     getOrders(selectedCurrencyStore);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigate]);
  // useEffect(() => {
  //   console.log("for local",basketStatesFromLocalStorage)
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [basketStatesFromLocalStorage]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isAppealPage>
      <Helmet>
        <title>{`${productTitle} | MATW Project`}</title>
        <meta name="title" content={`${productTitle} | MATW Project`} />
        <meta name="description" content={`${currentProduct ? currentProduct.description : ''}`} />
        <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content="MATW-Project" />
          <meta itemprop="name" content={`${currentProduct ? currentProduct.name : ''} - MATW Project`} />
          <meta itemprop="description" content={`${currentProduct ? currentProduct.description : ''}`} />
          <meta itemprop="productID" content={`${currentProduct ? currentProduct.creator : ''}`} />
          <meta itemprop="url" content={window.location.href} />
          <meta itemprop="image" content={currentProduct ? currentProduct.image_link : ''} />
          <meta itemprop="price" content={currentProduct ? currentProduct.unit_amount : '100'} />
        </div>
      </Helmet>
      <AppealCurrentProduct currentProduct={currentProduct} goal={goal} campaign={campaign} />
      {/* ------------------- Products Section -------------------- */}
      <AppealDetailsTabs
        givingProducts={<AppealLevelGiving givingProducts={givingProducts} title="Select a Giving Level" isDetailsPage/>}
        donors={<AppealDonors donors={donors} currency={selectedCurrency} usedOnTab />}
        impact={<AppealImpact usedOnTab />}
        appeals={<AppealWidget className="bg-white" appealProducts={appealProducts} usedOnTab />}
        lang={lang}
        handleImpactSelected={() => {
          console.log("impact ref=", impactRef, impactRef.current, impactRef.current.offsetTop)
          // window.scrollTo({ behavior: 'smooth', top: impactRef.current.offsetTop - 715 })
          // window.scrollTo({ behavior: 'smooth', top: impactRef.current.offsetTop })
          // impactRef.current.scrollIntoView();
          // impactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          const section = document.querySelector('#impacts');
          section.scrollIntoView({ block: 'nearest', top: impactRef.current.offsetTop - 200 });
          // section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }}
      />
      {/* /* --------------------------------- Youtube -------------------------------- */}
      <section className="w-full md:container md:mx-auto py-4 md:py-8 px-4 md:px-0">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          {/* <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} /> */}
          <div className={`basis-full min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[890px]`}>
            <iframe
              className="video"
              width={'100%'}
              height={'100%'}
              title={'youtube'}
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed`}
            />
          </div>
        </div>
      </section>

      {/* /* ---------------------------------- Text ---------------------------------- */}
      <section className="md:container md:mx-auto px-4 md:px-0 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-LightBlue ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } mb-5 md:mb-8 md:text-center`}
        >
          {/* <span className="text-DarkerBlue text-4xl font-gotcha mr-4">Adipiscing Elit</span>Lorem ipsum dolor */}
          {campaign === 'palestine' ? 'OUR MATW TEAM HAS NOT STOPPED' : 'THEY NEED US NOW MORE THAN EVER'}
        </h1>
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
            }`}
        >
          {campaign === 'palestine' ? (
            <>
              <p>
                {' '}
                We’re on the ground providing life-saving assistance for displaced families in Palestine aswell as those who have fled to neighbouring
                countries seeking refuge. With each day that goes by, the situation becomes more dire and needs become more urgent.
              </p>
              <p>Your support is needed now more than ever.</p>
              <p>
                You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
                family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
                Zakat-applicable.
              </p>
            </>
          ) : (
            <>
              <p>
                Surviving persecution, these families have done what they can to survive. As Muslims its our duty to support them as much as we can.
                This is why we have active teams on the ground in neighbouring countries like Egypt, ensuring that every refugee family we support
                gets the right kind of help they urgently need.
              </p>
              <p>
                We don’t leave vulnerable families on the streets, we make sure they have enough provisions to start again. We provide things like,
                temporary and permanent housing solutions, furniture like beds, tables, chairs, sofas, mattresses and blankets as well as white goods
                such as ovens, fridges, microwaves and washing machines. We also provide household and hygiene essentials, clothing and food. As well
                as, vocational training, healthcare and psychological support for the families. Finally we also provide a cash stipend for the
                families too.
              </p>
            </>
          )}
        </div>
      </section>
      {/* /* --------------------------------- Impacts -------------------------------- */}
      <div id='impacts' ref={impactRef}><AppealImpact /></div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------- videos section ------------------------------------ */}
      {/* <AppealVideos campaign={campaign} /> */}
      <section className="mt-4 md:mt-12 mb-6">
        {window.location.pathname === '/appeals/palestine-emergency' ? <CarouselImages carouselImages={carouselImages} /> : <ImagesSection />}
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ------------------------------ Appeal Widget ----------------------------- */}
      <AppealWidget appealProducts={appealProducts} />
      {/* /* --------------------------------- Donors --------------------------------- */}
      <AppealDonors donors={donors} currency={selectedCurrency} />
    </Layout>
  );
};
// export const startDateOfAppealCampaign = new Date().toISOString().split('T')[0];
export const startDateOfAppealCampaign = '2024-07-27';
export const appealProductsCodes = [
  // 'MA-PAL-GL-1-001',
  // 'MA-PAL-GL-3-001',
  // 'MKD-MN-001',
  // 'FWD-DIS-WPV-2023-01-054',
  // 'EMR-DIS-PSE-2023-02',
  // 'EMR-DIS-MDPL-2023-01-102',
  // 'EMR-DIS-BFPL-2023-01-202',
  // 'EMR-DIS-BFPL-2023-01-203',
  // 'EMR-GEN-RHP-2023-01-131',
  // 'EMR-DIS-PSE-2023-03',
  // 'EMR-DIS-PSE-2023-04',
  // 'EMR-DIS-PSE-2023-05',
  // 'EMR-DIS-PSE-2023-06',
  // 'EMR-DIS-PSE-2023-01-01',
  // 'EMR-DIS-BFPL-2023-01-102',
  // 'EMR-DIS-MDPL-2023-01-102',

  'MA-PAL-GL-1-001',
  'MA-PAL-GL-1-002',
  'MA-PAL-GL-1-003',
  'MA-PAL-GL-1-004',
  'MA-PAL-GL-1-005',
  'MA-PAL-GL-1-006',
  'MA-PAL-GL-1-007',
  'MA-PAL-GL-1-008',
  'MA-PAL-GL-1-009',
  // 'MA-PAL-GL-2-001',
  // 'MA-PAL-GL-2-002',
  // 'MA-PAL-GL-2-003',
  // 'MA-PAL-GL-2-004',
  // 'MA-PAL-GL-2-005',
  // 'MA-PAL-GL-2-006',
  // 'MA-PAL-GL-2-007',
  // 'MA-PAL-GL-2-008',
  // 'MA-PAL-GL-2-009',
  'MA-PAL-GL-3-001',
  'MA-PAL-GL-3-002',
  'MA-PAL-GL-3-003',
  'MA-PAL-GL-3-004',
  'MA-PAL-GL-3-005',
  'MA-PAL-GL-3-006',
];

export default MATWAppealDetailsLandingPage;