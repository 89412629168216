import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import ShowCurrencies from '../../../utils/showCurrencies';
import SelectCurrency from '../../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import useHandleAddProduct from '../../../utils/handleAddProduct';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const ChildrenOfGazaCrisisWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, defaultProduct, mode, donateBtnText, arabic, products, lang = 'en' } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];

    products.forEach(item => {
      tempProductsList.push({
        value: item.id,
        label: item.name,
        creator: item.creator,
        [selectedCurrencyStore.toLowerCase()]: item[selectedCurrencyStore.toLowerCase()],
        image_link: item.image_link,
      });
    });

    setProductsList(tempProductsList);
    setAllProducts(tempProductsList);
  };
  const handleSelectedProduct = product => {
    const selectedProduct = allProducts.find(item => item.creator === product.creator);

    if (selectedProduct) {
      setSelectedProduct(selectedProduct);
    }
  };
  const suggestedRecurringModes = [
    { value: 'one-off', label: 'Single' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    tempProduct.name = selectedProduct.label;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: 1,
      recurring: selectedRecurring.value,
    });
    console.log('before send', tempProduct);
    addProduct({ product: tempProduct, currency: currentCurrency });
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
    } else {
      setCurrentCurrency('AUD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && !allProducts.length && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <div
      dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${
        arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''
      } w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}
    >
      <div className="w-full grid xl:grid-flow-col auto-cols-max gap-2 place-content-center">
        <div className="flex gap-2">
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full min-w-[260px]"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>

        <div className="flex gap-2">
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>

        <div className="flex justify-center items-center gap-2 backg bg-white text-[#F60362] w-36 rounded-md">
          {ShowCurrencies(currentCurrency, selectedProduct[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], false)}
        </div>
        <button
          className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
            isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
          }`}
          onClick={handleAddNewProduct}
        >
          {donateBtnText ? donateBtnText : lang === 'fr' ? 'Faire un don rapide' : arabic || lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
        </button>
      </div>
    </div>
  );
};

export default ChildrenOfGazaCrisisWidget;
