import React, { useState } from 'react';
import { useRef } from 'react';
import CustomButton from '../../../components/general/button';
import Sticky from 'react-sticky-el';

const AppealDetailsTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { givingProducts, donors, impact, appeals, lang = 'en', handleImpactSelected } = props;
  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const contentRef = useRef(null);

  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      tabTitle: lang === 'fr' ? 'Urgence Palestinienne' : lang === 'ar' ? 'فلسطين الطوارئ' : 'Level of Giving',
    },
    {
      tabTitle: lang === 'fr' ? 'sauver les orphelins' : lang === 'ar' ? 'إنقاذ الأيتام' : 'Donors',
    },
    {
      tabTitle: lang === 'fr' ? 'Installation des réfugiés palestiniens' : lang === 'ar' ? 'مستوطنة اللاجئين الفلسطينيين' : 'Impact',
    },
    {
      tabTitle: lang === 'fr' ? 'La faim en Palestine' : lang === 'ar' ? 'فلسطين جوعى' : 'Other Appeals',
    },
  ];
  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    if(index === 2) {
      handleImpactSelected();
    } else {
      window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    }
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */

  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]-" dir={lang === 'ar' ? 'rtl' : 'ltl'}>
      <div className={`${(activeTabProducts === 1 || activeTabProducts === 3) ? '' : 'md:container'} w-full mx-auto px-4 md:px-0 py-4 md:py-12`}>
        <div className="hidden sm:flex items-center w-full overflow-x-auto">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {tabButtonsProducts.map(({ tabTitle }, index) => (
              <CustomButton
                key={`btn_${index}`}
                title={tabTitle}
                onClick={handleTabChange.bind(this, index)}
                className={`${index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'} mr-2 md:mr-0 min-w-fit text-[14px] !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'}`}
              />
            ))}
          </div>
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10 bg-white">
          <Sticky
           stickyStyle={{ top: '64px', bottom: 'unset' }}
           stickyClassName="!w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_15px_25px_-1px] z-10 bg-white left-0"
           mode="top"
           onFixedToggle={() => { setIsTabsFixed(!isTabsFixed)}}
          >
            <div className={`flex gap-1 items-center w-full justify-around mx-auto ${isTabsFixed ? '' : 'overflow-x-auto'}`}>
              {tabButtonsProducts.map(({ tabTitle }, index) => (
                <CustomButton
                  key={`btn_${index}`}
                  title={tabTitle}
                  onClick={handleTabChange.bind(this, index)}
                  className={`${isTabsFixed
                    ? index === activeTabProducts ? '!text-[#F60362] !bg-white' : '!text-[#78716C] !bg-white text-[10px] !px-0 !py-1'
                    : index === activeTabProducts ? '!bg-DarkerBlue text-white !rounded-full p-2 min-w-fit' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue mr-2 min-w-fit text-[14px] !rounded-full p-2'}
                     ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'}`
                  }
                />
              ))}
            </div>
          </Sticky>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            {activeTabProducts === 0 && <div ref={contentRef}>{givingProducts}</div>}
            {activeTabProducts === 1 && <div ref={contentRef}>{donors}</div>}
            {/* {activeTabProducts === 2 && <div ref={contentRef}>{impact}</div>} */}
            {activeTabProducts === 2 && <div ref={contentRef}></div>}
            {activeTabProducts === 3 && <div ref={contentRef}>{appeals}</div>}
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppealDetailsTabs;