import foodPackIcon from '../../../images/landings/food-aid/food-icon.png';
import helpIcon from '../../../images/landings/food-aid/help-icon.png';
import waterIcon from '../../../images/landings/food-aid/water-icon.png';
import beneficiariesIcon from '../../../images/landings/food-aid/beneficiaries-icon.png';

// Impact Slider Data
export const impactSliderData = [
  {
    img: { src: waterIcon, alt: 'Litres water served' },
    value: '4 Million L',
    title: 'Litres water served',
    arabicTitle: '',
  },
  {
    img: { src: beneficiariesIcon, alt: 'Beneficiaries served' },
    value: '36k',
    title: 'Beneficiaries served',
    arabicTitle: '',
  },
  {
    img: { src: foodPackIcon, alt: 'Food packs distributed' },
    value: '245k',
    title: 'Food packs distributed',
    arabicTitle: '',
  },
  {
    img: { src: helpIcon, alt: 'Beneficiaries served' },
    value: '2.9 Million',
    title: 'Beneficiaries served',
    arabicTitle: '',
  }
];
