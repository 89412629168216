import { useEffect, useState, useRef } from 'react';
import { Base64 } from 'js-base64';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowCurrencies from '../../utils/showCurrencies';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';

const QurbanProductBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const ref = useRef(null);
    const { product, currency, isStaticProduct } = props;
    const [showMore, setShowMore] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCurrency = (currency) => {
        return ShowCurrencies(currency, product[currency.toLowerCase()], false);
    }
    const handleCustomProductAmount = (product) => {
        if (
            Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) ||
            product.id === 52 ||
            product.id === 69
        ) {
            setIsAnyAmount(true);
        } else {
            if (Number(product[currency.toLowerCase()]) === 1) {
                setIsAnyAmount(true);
            } else {
                setIsAnyAmount(false);
            }
        }
    }
    const handleHeight = () => {
        setShowMore(!showMore);
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        handleCustomProductAmount(product)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    useEffect(() => {
        ref.current.clientHeight > 80 && setIsOverflow(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full md:pb-5 rounded-xl shadow-lg bg-white">
            <div className="overflow-hidden rounded-md max-h-[336px]">
                {product.image_link
                    ? <LazyLoadImage
                        alt={product.name}
                        effect="blur"
                        width={`100%`}
                        delayTime={500}
                        className='rounded'
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/general/logo-replace.png";
                        }}
                        delayMethod='debounce'
                        // height={image.height}
                        src={product.image_link} // use normal <img> attributes as props
                    // width={image.width}
                    />
                    : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                }
            </div>
            <div className="p-2 md:p-3">
                <h3 className=" text-sm sm:text-md md:text-lg lg:text-lg xl:text-xl text-center h-[30px] sm:h-[35px] lg:h-[50px]  my-2 leading-4 md:leading-none">{product.name}</h3>
                <div className='flex justify-center h-[40px]'>
                    <h2 className="text-[#f60362] mr-1 col-span-6 text-md md:text-xl text-center border border-[#f60362] rounded-md px-2 h-6 md:h-10 flex items-center">
                        {isStaticProduct
                            ? ' '
                            : isAnyAmount
                                ? 'Any Amount'
                                : <>{handleCurrency(currency)} <span className={`uppercase`}>{currency}</span></>
                        }
                    </h2>
                </div>
                <div className="relative">
                    <div className={`text-center my-2 relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[80px]'} ${product.description.split(' ').length > 28 ? 'mb-6' : 'mb-6'}`}>
                        <p ref={ref} className={`text-stone-400 text-xs sm:text-sm md:text-base lg:text-md overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[79px] `}>
                            {product.description}
                        </p>
                    </div>
                    {isOverflow && <span className='text-[#00a3da] block w-full absolute cursor-pointer -bottom-[22px] md:-bottom-[18px] text-center' onClick={() => handleHeight()}>{showMore ? 'Show less' : 'Show more'}</span>}
                </div>
                <div className="grid grid-cols-6 gap-1 2xl:gap-2">
                    {isStaticProduct
                        ? <Link to={`/zakat-calculator`} className='text-white text-sm md:text-md lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-full transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer px-2 py-2 justify-center items-center'>
                            Zakat Calculator
                        </Link>
                        : <Link to={`/checkout?id=${Base64.encode(JSON.stringify({ product: product && product.creator, currency: currency, id: product && product.id, quantity: isAnyAmount ? 100 : null, category_id: product && product.category_id }))}`}
                            className='text-white text-sm md:text-md uppercase lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-full transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer px-2 py-2 justify-center items-center'
                            onClick={(e) => { e.preventDefault(); addProduct({product: product, currency: currency}) }}
                        >
                            Donate Now
                        </Link>
                    }
                    <div className="hidden col-span-6 xl:col-span-4 xl:col-start-2 flex- rounded-full transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer px-2 py-2 justify-center items-center">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QurbanProductBox;
