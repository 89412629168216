import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import SeoData from '../../../utils/seo.json';
import Layout from '../../../components/general/layout';
import { selectProducts } from '../../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import WidgetHome from '../../../components/widgets/widgetHome';
import Top10ProductBox from './top10ProductBox';
import services from '../../../services';


const Top10Products = () => {
  // const [products, setProducts] = useState();
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [view, setView] = useState('grid');
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    // let tempTopProducts = [];

    // products.map(item => {
    //   item.sub_categories.map(inner_item => {
    //     inner_item.products.map(inner_item_product => {
    //       // Checkbox products
    //       if (inner_item_product.creator === 'QB-2024-S19') tempTopProducts[0] = inner_item_product;
    //       if (inner_item_product.creator === 'QB-2024-S18') tempTopProducts[1] = inner_item_product;
    //       if (inner_item_product.creator === 'QB-2024-S20') tempTopProducts[2] = inner_item_product;
    //       if (inner_item_product.creator === 'QB-2024-S11') tempTopProducts[3] = inner_item_product;
    //       if (inner_item_product.creator === 'QB-2024-S10') tempTopProducts[4] = inner_item_product;
    //       if (inner_item_product.creator === 'QB-2024-S12') tempTopProducts[5] = inner_item_product;
    //       if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') tempTopProducts[6] = inner_item_product;
    //       if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') tempTopProducts[7] = inner_item_product;
    //       if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') tempTopProducts[8] = inner_item_product;
    //       if (inner_item_product.creator === 'TK-QB-2024-FA-00') tempTopProducts[9] = inner_item_product;
    //       return inner_item_product;
    //     });
    //     return inner_item;
    //   });
    //   return item;
    // });
    getTop10Products()
    // tempTopProducts = tempTopProducts.filter(n => n)
    // setTopProducts(tempTopProducts)
  };
  const getTop10Products = async () => {
    // setIsLoading(true);
    let tempTopProducts = [];
    let tempSearch = {
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0]
    };
    try {
      const response = await services.getTop20Products(tempSearch);
      // console.log("ProductsTransactionTotalReport", response);
      let temp = [];
      response.data.map(item => {
        item.product_name !== 'Admin Contribution' && temp.push(item)
        return item;
      })
      let compare = (a, b) => {
        if (a.total_sales < b.total_sales) {
          return -1;
        }
        if (a.total_sales > b.total_sales) {
          return 1;
        }
        return 0;
      }
      let sortable = temp.sort(compare).reverse();
      // let sortable = topProducts.sort(compare).reverse();
      let _top10 = sortable.slice(0, 11);
      // console.log("sortable", sortable);
      // console.log("sortable", _top10);
      products.map(item => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            _top10.map((_item,index) => {
              if (inner_item_product.name === _item.product_name) {
                tempTopProducts[index] = inner_item_product
              }
              return null;
            })
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      // setProductsCount(tempProductsCount);
      // setProductsName(tempProductsName);
      // setTopProducts(_top10)
      function uniqBy(a) {
        var seen = {};
        return a.filter(function (item) {
          var k = item.creator;
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
      }
      let tempNewProducts = uniqBy(tempTopProducts)
      tempNewProducts = tempNewProducts.slice(0, 10);
      tempNewProducts = tempNewProducts.filter(n => n)
      // console.log("tempNewProducts=", tempNewProducts)
      setTopProducts(tempNewProducts)
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error('error========', error);
    }
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <Layout showRegularImg={false}>
      {seo &&
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <meta name="title" content={`${seo['appeals']['title']}`} />
          <meta name="description" content={`${seo['appeals']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="w-full px-4 md:px-2 lg:px-0">
          <h1 className="w-full text-center text-4xl md:text-5xl lg:text-7xl text-[#253b7e] py-2 font-['Gotcha'] mt-6">Top 10 Most Popular</h1>
          <div className="flex md:hidden pt-4 mb-4 justify-start items-center border-b border-stone-300">
            <div className={`flex rounded-t-md ${view === 'list' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('list')}>
              List
            </div>
            <div className={`flex rounded-t-md ${view === 'tile' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('tile')}>
              Tile
            </div>
            <div className={`flex rounded-t-md ${view === 'grid' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('grid')}>
              Grid
            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <div className="flex justify-center flex-wrap">
                {topProducts.map((prod,index) => {
                  return prod.name !== 'Pakistan Solar Powered Well' && (
                    <div className={`${view === 'list' ? 'basis-full' : view === 'tile' ? 'basis-1/2 px-1' : 'basis-1/3 px-1'} sm:basis-1/3 md:basis-1/3 xl:basis-1/5 py-1 md:p-2`} key={`top10${index}`}>
                      <Top10ProductBox product={prod} currency={selectedCurrency} view={view} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Top10Products;
