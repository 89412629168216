import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { selectSelectedCurrency } from "../../../store/user";
import ShowCurrencies from "../../../utils/showCurrencies";
import useHandleAddProduct from "../../../utils/handleAddProduct";
import services from "../../../services";
import SelectCurrency from "../../../components/general/selectCurrency";
// import { selectProducts } from "../../../store/products";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const WidgetEmbedded = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    // const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    // const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    // const [selectedRecurringMode, setSelectedRecurringMode] = useState('one-off');
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState({
        creator: "MKD-GEN-MED-2023-01-122",
        label: "General Orphan Support",
        value: 124
    });
    const [addProduct] = useHandleAddProduct();
    const suggestedPrices = [
        10, 20, 30, 50, 100
    ]
    const suggestedRecurringModes = [
        { label: 'Single', value: 'one-off' },
        { label: 'Weekly', value: 'week' },
        { label: 'Monthly', value: 'month' }
    ]
    const [selectedRecurring, setSelectedRecurring] = useState(suggestedRecurringModes[0]);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = async () => {
        // setIsLoaded(true);
        try {
            const response = await services.allProducts();
            let tempMakeDonationProducts = [];
            let tempProductsList = [];
            response.data.map(item => {
                item.sub_categories.map(inner_item => {
                    let tempSubCat = inner_item;
                    tempSubCat.products = inner_item.products.filter(inner_item_product => {
                        if (Number(inner_item_product.status) === 1) {
                            if (inner_item_product.creator === "MKD-GEN-MED-2023-01-122") {
                                setSelectedProduct(inner_item_product)
                            }
                            if (inner_item.name === 'Earthquake Appeal') {
                                if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Morocco Appeal
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                                if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') { // Afghanistan Earthquake Appeal
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Libya Floods') {
                                if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Libya Floods
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Winter Campaign') {
                                if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') { // Keep Orphans Warm Where Most Needed
                                    tempMakeDonationProducts.push(inner_item_product);
                                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                                }
                            };
                            if (inner_item.name === 'Make A Donation') {
                                tempMakeDonationProducts.push(inner_item_product);
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                            }
                        }
                        return inner_item_product;
                        // return inner_item_product;
                    });
                    return tempSubCat;
                });
                return item;
            });
            // console.log("deactive products=",tempDeactiveProducts)
            // setAllProducts(tempProducts);
            setProductsList(tempProductsList);
            setAllProducts(tempMakeDonationProducts)
            // setProductsList(tempProductsList);
        } catch (error) {
            console.error('error========', error);
        }
    };
    // const handleCurrency = (currency) => {
    //     setCurrentCurrency(currency.value)
    //     // selectedCurrency(currency);
    // }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurring.value,
        })
        // console.log("product=",tempProduct,currentCurrency)
        addProduct({product: tempProduct, currency: currentCurrency, openOnNewTab: true})
    }
    const handleSelectedProduct = product => {
        allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
    };
    // const handleSelectedItem = (currency, index) => {
    //     let tempSelectedPrice = {};
    //     tempSelectedPrice.index = index;
    //     tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    //     setSelectedPrice(tempSelectedPrice);
    // };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        getAllProducts();
    }, [])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`w-[98%]- w-full md:container md:mx-auto rounded-lg py-2 md:py-4 flex flex-wrap items-center bg-[#E2EFF3]`}>
            <div className="w-full grid md:grid-cols-1 xl:grid-flow-col md:auto-cols-max flex- gap-2 ">
                <div className="flex flex-col md:flex-row gap-2">
                    <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} hideFlag className='[&>div]:h-[48px]' />
                    <ReactSelect
                        options={productsList}
                        className="max-h-[48px] w-full min-w-[260px]"
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        placeholder="Select Product"
                        onChange={e => handleSelectedProduct(e)}
                    />
                </div>
                <div className="hidden md:flex gap-2">
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className="[&>*]:!cursor-pointer w-full min-w-[150px]"
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={e => {
                            setSelectedRecurring(e);
                        }}
                    />
                    {suggestedPrices.map((price, index) => {
                        return (
                            <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                                <div
                                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] rounded-md ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                    onClick={() => {
                                        // handleSelectedItem(currentCurrency, index);
                                        setSelectedPrice(price);
                                        setProductAmount('');
                                    }}
                                >
                                    {ShowCurrencies(currentCurrency, price, false)}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex flex-col md:hidden gap-2">
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className="[&>*]:!cursor-pointer w-full min-w-[150px]"
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={e => {
                            setSelectedRecurring(e);
                        }}
                    />
                    <div className="grid grid-cols-5 w-full gap-2">
                        {suggestedPrices.map((price, index) => {
                            return (
                                <div key={`prices${index}`} className="col-span-1">
                                    <div
                                        className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-1 py-2 md:py-4 min-w-[60px] rounded-md ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                        onClick={() => {
                                            // handleSelectedItem(currentCurrency, index);
                                            setSelectedPrice(price);
                                            setProductAmount('');
                                        }}
                                    >
                                        {ShowCurrencies(currentCurrency, price, false)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                    <input
                        value={productAmount}
                        className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
                        type="number"
                        onChange={e => {
                            setProductAmount(e.target.value);
                            setSelectedPrice(null);
                        }}
                        placeholder="Other amount"
                    />
                    <button
                        disabled={!selectedProduct.name}
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${selectedProduct.name ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={handleAddNewProduct}
                    >
                        'QUICK DONATE'
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WidgetEmbedded;
