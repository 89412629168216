import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const DuaForBreakingFast = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));

    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['duaForBreakingFast']['title']}`}</title>
                    <meta name="title" content={`${seo['duaForBreakingFast']['title']}`} />
                    <meta name="description" content={`${seo['duaForBreakingFast']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            {/* <h1 className='font-gotcha text-[30px] sm:text-[50px] leading-[70px] text-center text-[#253B7E]'>
                                Our Promise To You
                            </h1> */}
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    The Spiritual Essence of Iftar: Understanding the Dua for Breaking Fast
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-brandingMedium'>
                                    As the sun dips below the horizon and the day's fast comes to an end, Muslims around the world engage in a moment of profound spiritual
                                    significance. This moment, known as Iftar, is not just about partaking in the evening meal but is deeply intertwined with the act of making dua,
                                    or supplication, to Allah. Among the duas recited at this time, two hold a special place in the hearts of the faithful.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The First Dua: A Declaration of Faith and Gratitude
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    The first dua, recited as the fast is broken, encapsulates the physical and spiritual relief that comes with Iftar. In Arabic, it is recited as:
                                </p>
                                <ul className='mt-5 space-y-8'>
                                    <li className="space-y-5">
                                        <div>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>"ذَهَبَ الظَّمَأُ وَابْتَلَّتِ الْعُرُوقُ وَثَبَتَ الأَجْرُ إِنْ شَاءَ اللَّهُ"</h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                <strong className='block text-gray-700 mb-2'>Transliterated, it reads:</strong>
                                                "Dhahaba al-zama’ wa abtalat al-‘urooq wa thabata al-ajr in sha Allah."
                                            </p>
                                        </div>
                                        <div>
                                            <p className='text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                <strong className='block text-gray-700 mb-2'>The English translation of this poignant supplication is:</strong>
                                                "Thirst is gone, the veins are moistened and the reward is certain if Allah wills."
                                            </p>
                                            <p className='mt-4 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                This dua, recorded in Abu Dawud (2357), reflects a deep sense of gratitude and acknowledgment of Allah's sustenance. It speaks to the physical relief of quenching thirst and nourishing the body, but more so, it highlights the spiritual fulfillment of completing the fast. The mention of "the reward is certain if Allah wills" reinforces the faith in Allah's mercy and the hope for His acceptance.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Second Dua: Affirming Intent and Allah's Providence
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Following the first, the second dua further emphasizes the relationship between the fasting individual and Allah, stating:
                                </p>
                                <ul className='mt-5 space-y-8'>
                                    <li className="space-y-5">
                                        <div>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold'>"اللَّهُمَّ لَكَ صُمْتُ وَعَلَى رِزْقِكَ أَفْطَرْتُ"</h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                <strong className='block text-gray-700 mb-2'>In transliteration:</strong>
                                                "Allahumma inni laka sumtu wa ala rizq-ika-aftartu."
                                            </p>
                                        </div>
                                        <div>
                                            <p className='text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                <strong className='block text-gray-700 mb-2'>And in English, it translates to:</strong>
                                                "O Allah! For You, I have fasted and upon Your provision, I have broken my fast."
                                            </p>
                                            <p className='mt-4 text-[#78716C] text-[16px] sm:text-[20px] font-brandingMedium'>
                                                Found in Abu Dawud (2358), this dua is a personal declaration of the fast being for Allah alone, highlighting the act of fasting as one of worship and dedication to the Divine. It acknowledges that the sustenance provided to break the fast is a blessing from Allah, reinforcing a sense of reliance on and gratitude towards Allah.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className='mt-10'>
                                <h3 className='text-[#253B7E] text-[20px] sm:text-[36px] font-bold'>
                                    The Significance of These Duas
                                </h3>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Both duas serve as reminders of the purpose of fasting in Islam - to cultivate piety, patience, and gratitude. They are profound expressions
                                    of faith that encapsulate the essence of Ramadan. They remind the faithful of the transient nature of physical needs and the enduring presence of divine grace.
                                </p>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    By reciting these duas, Muslims affirm their commitment to their faith, express their gratitude for Allah's blessings, and acknowledge their dependence on His
                                    sustenance. It's a moment that transcends the physical act of eating and drinking, elevating the Iftar.
                                </p>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    The duas for breaking the fast are integral to the Iftar, weaving together threads of gratitude, faith, and humility. They are a testament to the spiritual depth of Ramadan,
                                    serving as a daily reminder of the blessings bestowed by Allah and the ultimate purpose of our earthly endeavors. As the fast is broken each day, these duas echo in homes
                                    and mosques around the world, uniting the ummah in a shared expression of faith and devotion.
                                </p>
                            </div>

                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/ali-banat')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/purpose')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default DuaForBreakingFast