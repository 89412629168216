import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import { selectProducts } from '../../store/products';
import AppealLevelGiving from './components/appealLevelGiving';
import services from '../../services';
import Layout from '../../components/general/layout';
import Skeleton from 'react-loading-skeleton';
import { appealProductsCodes, startDateOfAppealCampaign } from './details';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import SelectCurrency from '../../components/general/selectCurrency';
import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';
import { useNavigate } from 'react-router-dom';

const MATWAppealLandingPage = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [productTitle, setProductTitle] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [currentProduct, setCurrentProduct] = useState();

  // const givingLevelProductsCodes = [
  //   'MKD-MN-001',
  //   'FWD-DIS-WPV-2023-01-054',
  //   'EMR-DIS-PSE-2023-02',
  //   'EMR-DIS-MDPL-2023-01-102',
  //   'EMR-DIS-BFPL-2023-01-202',
  //   'EMR-DIS-BFPL-2023-01-203',
  //   'EMR-DIS-BFPL-2024-01-207',
  //   'EMR-DIS-AA-2024-01-207',
  //   'EMR-DIS-PSE-2023-01',
  //   'EMR-DIS-BFPL-2023-01-204',
  //   'EMR-GEN-RHP-2023-01-131',
  //   'EMR-DIS-PSE-2023-03',
  //   'EMR-DIS-PSE-2023-04',
  //   'EMR-DIS-PSE-2023-05',
  //   'EMR-DIS-PSE-2023-06',
  //   'EMR-DIS-PSE-2023-01-01',
  //   'EMR-DIS-BFPL-2023-01-102',
  //   'EMR-DIS-MDPL-2023-01-102',
  // ];
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = (_prds) => {
    setIsLoaded(true);
    if (products && Object.keys(products).length && !isLoaded) {
      let tempGivingProducts = [];
      let tempAppealProducts = [];
      // let pathName = window.location.pathname.split('/')[3].split("-").join(" ").toLowerCase();
      // console.log("on all produtc=", _prds, pathName)
      products.map(item => {
        item.sub_categories.map(inner_item => {
          if (inner_item.name === 'Palestine Emergency' && campaign === 'palestine') {
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine'
              });
              _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                }
                return _prdItem
              })
              tempGivingProducts.push(inner_item_product)
              if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
                setCurrentProduct(inner_item_product)
                setProductTitle(inner_item_product.name)
                tempAppealProducts[0] = inner_item_product
              }
              return inner_item_product;
            })
          }
          if (inner_item.name === 'Palestine Refugee Resettlement' && campaign === 'egyptian') {
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian'
              });
              _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product.total_count = _prdItem.total_count;
                  inner_item_product.total_sale = _prdItem.total_sale;
                }
                return _prdItem
              })
              tempGivingProducts.push(inner_item_product)
              if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
                setCurrentProduct(inner_item_product)
                setProductTitle(inner_item_product.name)
                tempAppealProducts[1] = inner_item_product;
              }
              return inner_item_product;
            })
          }
          return inner_item;
        });
        return item;
      });
      tempAppealProducts = tempAppealProducts.filter(n => n);
      tempGivingProducts = tempGivingProducts.filter(n => n)
      // console.log("setAppealProducts=", tempAppealProducts,tempGivingProducts)
      setGivingProducts(tempGivingProducts);
    } else {
      // getLiveAllProducts(_prds)
    }
  };


  const getOrders = async (_currency) => {
    let req = {
      products: appealProductsCodes,
      // limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign
      // start_date: '2024-02-23'
    }
    try {
      const response = await services.getLatestOrders(req);
      // console.log('get orders=', products, response.data);
      // setAppealProducts(response.data)
      getAllProducts(response.data)
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleAddProduct = () => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    console.log("basketStatesFromLocal Storage=", basketStatesFromLocalStorage)
    // addProduct(currentProduct, selectedCurrency, 'single', false, true, basketStatesFromLocalStorage)
    addProduct({ product: currentProduct, currency: selectedCurrency })
  }
  // const handleBack = () => {
  //   // navigate(-1);
  //   if (window.history.state && window.history.state.idx > 0) {
  //     navigate(-1);
  //   } else {
  //     navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
  //   }
  // };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore && products && Object.keys(products).length && !isLoaded) {
      getOrders(selectedCurrencyStore);
    }
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, products]);
  // useEffect(() => {
  //   console.log("for local",basketStatesFromLocalStorage)
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [basketStatesFromLocalStorage]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isAppealPage>
      <Helmet>
        <title>{`${productTitle} | MATW Project`}</title>
        <meta name="title" content={`${productTitle} | MATW Project`} />
        <meta name="description" content={`${currentProduct ? currentProduct.description : ''}`} />
        <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content="MATW-Project" />
          <meta itemprop="name" content={`${currentProduct ? currentProduct.name : ''} - MATW Project`} />
          <meta itemprop="description" content={`${currentProduct ? currentProduct.description : ''}`} />
          <meta itemprop="productID" content={`${currentProduct ? currentProduct.creator : ''}`} />
          <meta itemprop="url" content={window.location.href} />
          <meta itemprop="image" content={currentProduct ? currentProduct.image_link : ''} />
          <meta itemprop="price" content={currentProduct ? currentProduct.unit_amount : '100'} />
        </div>
      </Helmet>
      <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 pb-4 md:py-8">
        <div className="col-span-2 px-6 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
          <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
            {currentProduct ? currentProduct.name : <Skeleton height={48} />}
          </h3>
          <div className="w-full relative">
            {currentProduct ? <>
              <div className={`my-2 relative overflow-hidden h-auto ${currentProduct.description.split(' ').length > 28 ? 'mb-4' : 'mb-4'}`}>
                <p className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
                  {currentProduct.description}
                </p>
              </div>
            </> : <Skeleton count={4} />}
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 p-4 md:p-0 flex">
          {currentProduct
            ? <>
              {/* <CustomButton
                onClick={() => handleBack()}
                title={'Back'}
                type="button"
                className={`!h-[38px] w-full sm:w-auto !bg-[#00a3da] mr-2`} /> */}
              <CustomButton
                onClick={() => handleAddProduct()}
                title={'Donate Now'}
                type="button"
                className={`!h-[38px] w-full sm:w-auto mr-2`} />
              <SelectCurrency onChangeCurrency={(e) => setSelectedCurrency(e)} className="sm:w-auto" />
            </>
            : <Skeleton height={43} />
          }
        </div>
      </section>
      {/* ------------------- Products Section -------------------- */}
      <section dir={lang === 'ar' ? 'rtl' : 'ltl'}>
        <div className={`md:container w-full mx-auto px-4 md:px-0 py-4 md:py-8`}>
          <AppealLevelGiving showAllBoxes givingProducts={givingProducts} title='Select a Giving Level' />
        </div>
      </section>

    </Layout>
  );
};

export default MATWAppealLandingPage;