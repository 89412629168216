import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
// import ShowCurrencies from "../../utils/showCurrencies";
import SelectCurrency from "../../components/general/selectCurrency";
import ReactSelect from "react-select";
// import ConvertUSD from "../../utils/convertUSD";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";
import { useNavigate } from "react-router-dom";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const ZakatAlFitrWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { className, defaultProduct, mode, donateBtnText, arabic, fixPrice, zakatCalculator } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [addProduct] = useHandleAddProduct();
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const products = useSelector((state) => selectProducts(state));
    const [quantity] = useState('1');
    const navigate =useNavigate();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let crisisWidgetProducts = [];

        if (arabic) {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {
                        if (inner_item_product.creator === defaultProduct.creator) {
                            setSelectedProduct(inner_item_product)
                        }

                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') { // Morocco Appeal
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'فلسطين أكثر ما يحتاج', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') { // Afghanistan Earthquake Appeal
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطبية في فلسطين', creator: inner_item_product.creator })
                        }


                        if (inner_item_product.creator === 'MKD-GD-MP001') { // Libya Floods
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حيث يحتاج الأكثر', creator: inner_item_product.creator })
                        }


                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'نداء المغرب', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'فيضانات ليبيا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطارئة', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة غذائية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة المياه', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الأيتام حول العالم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'الصدقة الجارية', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'التعليم', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'مشروع النور', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'امشروع البناء', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'توليد الدخل', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في تركيا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في سوريا', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'RM24-ZM-013') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'زكاة المال', creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'INT-001') { // Keep Orphans Warm Where Most Needed
                            crisisWidgetProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: 'تنقية الربا', creator: inner_item_product.creator })
                        }

                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });

            tempProductsList = tempProductsList.filter(function ({ creator }) {
                return !this.has(creator) && this.add(creator);
            }, new Set())


        }
        else {
            products.map(item => {
                item.sub_categories.map(inner_item => {
                    inner_item.products.map(inner_item_product => {
                        if (inner_item.name === 'Build A Water Well') {
                            if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
                                crisisWidgetProducts.push(inner_item_product); // Water well
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                            }
                        };
                        if (inner_item.name === 'Orphans') {
                            if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
                                crisisWidgetProducts.push(inner_item_product); // Support an Orphan Centre
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                            }
                        };
                        if (inner_item.name === 'Caravan') {
                            if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
                                crisisWidgetProducts.push(inner_item_product); // Emergency Caravan Long Term Shelter
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                            }
                        };
                        if (inner_item.name === 'Palestine') {
                            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                                crisisWidgetProducts.push(inner_item_product); // Palestine Appeal
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                            }
                        };
                        if (inner_item.name === 'Make A Donation') {
                            crisisWidgetProducts.push(inner_item_product)
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                        };
                        if (inner_item.name === 'Winter Campaign') {
                            if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
                                crisisWidgetProducts.push(inner_item_product); // Keep Orphans Warm Where Most Needed
                                tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                            }
                        };
                        if (defaultProduct) {
                            if (inner_item_product.creator === defaultProduct.creator) {
                                setSelectedProduct(inner_item_product);
                                if(!zakatCalculator){

                                    setProductAmount(inner_item_product[selectedCurrencyStore.toLowerCase()])
                                }

                            }
                        } else {
                            if (inner_item.name === 'Make A Donation') {
                                setSelectedProduct(inner_item.products[0])
                            };
                        }
                        return inner_item_product;
                    });
                    return inner_item;
                })
                return item;
            });
        }

        setProductsList(tempProductsList);
        setAllProducts(crisisWidgetProducts);
    };
    // const handleConvert = (currency, value) => {
    //     return Math.round(ConvertUSD(currency, value, false))
    // }
    const handleSelectedProduct = (product) => {
        allProducts.map(item => item.creator === product.creator && setSelectedProduct(item))
    }
    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice)
    }
    // const handleProductAmount = (currency, value) => {
    //     setProductAmount(handleConvert(currency, value))
    // }

    const suggestedPrices = fixPrice
        ? [
            {
                aud: 50,
                usd: 50,
                cad: 50,
                sgd: 50,
                gbp: 50,
                eur: 50,
                myr: 50,
                aed: 50,
                idr: 50,
            },
            {
                aud: 150,
                usd: 150,
                cad: 150,
                sgd: 150,
                gbp: 150,
                eur: 150,
                myr: 150,
                aed: 150,
                idr: 150,
            },
            {
                aud: 300,
                usd: 300,
                cad: 300,
                sgd: 300,
                gbp: 300,
                eur: 300,
                myr: 300,
                aed: 300,
                idr: 300,
            },
        ]
        : [
            {
                aud: 100,
                usd: 70,
                cad: 90,
                sgd: 90,
                gbp: 60,
                eur: 60,
                myr: 300,
                aed: 240,
                idr: 1002020,
            },
            {
                aud: 250,
                usd: 160,
                cad: 220,
                sgd: 220,
                gbp: 130,
                eur: 150,
                myr: 760,
                aed: 580,
                idr: 2505050,
            },
            {
                aud: 500,
                usd: 320,
                cad: 430,
                sgd: 440,
                gbp: 260,
                eur: 300,
                myr: 1510,
                aed: 1160,
                idr: 5010090,
            },
        ];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 1
    });
    const suggestedRecurringModes = [
        { value: 'one-off', label: arabic ? 'فردي' : 'Single' },
        { value: 'week', label: arabic ? 'أسبوعي' : 'Weekly' },
        { value: 'month', label: arabic ? 'شهري' : 'Monthly' }
    ]
    const [selectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
    const handleAddNewProduct = () => {
        // debugger
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: zakatCalculator ? customAmount : quantity,
            recurring: selectedRecurring.value
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {


            setCurrentCurrency(selectedCurrencyStore);
            // handleProductAmount(selectedCurrencyStore, selectedProduct[selectedCurrencyStore.toLowerCase()]);

            setProductAmount(selectedProduct[selectedCurrencyStore.toLowerCase()])
            // selectedPrice
            //     ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index)
            //     : handleProductAmount(selectedCurrencyStore, productAmount)
        } else {
            setCurrentCurrency('AUD')
            handleSelectedItem('AUD', selectedPrice.index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    useEffect(() => {
        products && !allProducts.length && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <Fragment>
            {zakatCalculator ?
                <div dir={arabic ? "rtl" : 'ltl'} className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}>
                    <div className="basis-full grid grid-cols-12 gap-2">
                        <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-8 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                            <ReactSelect
                                options={productsList}
                                className='max-h-[48px] w-full pointer-events-none'
                                defaultValue={selectedProduct}
                                isSearchable={false}
                                styles={styles}
                                placeholder='Select Product'
                                onChange={(e) => handleSelectedProduct(e)}
                                // isDisabled

                                readOnly
                            />
                        </div>
                        <div className="col-span-12 hidden md:flex md:col-span-6 lg:col-span-4  justify-center items-center-">
                            <button
                                className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                onClick={()=>navigate('/zakat-calculator')}
                            >Calculate your Zakat</button>
                        </div>
                        {/* <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className='[&>*]:!cursor-pointer w-full'
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => {
                            setSelectedRecurring(e);
                        }}
                    />
                </div> */}

<div className="col-span-7 sm:col-span-6 md:col-span-4 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2  min-[400px]:mb-0" style={styles.shadow}>
                            <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
                            <input
                                value={productAmount}
                                className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                                type="number"
                                onChange={(e) => {
                                    setProductAmount(e.target.value);
                                    setSelectedPrice(null);
                                }}
                                placeholder="Enter amount" />
                            {/* <input
                        value={quantity}
                        className="w-full p-2 rounded-lg h-full text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={(e) => {

                            if(e.target.value === '0') return;
                            if (!e.target.value) {
                                setProductAmount('');
                                setNumberOfFasts(e.target.value);
                                return;
                            }

                            let price = selectedProduct[`${currentCurrency.toLowerCase()}`];
                            let amount = formatNumberWithTwoDecimals(e.target.value * price)

                            setProductAmount(amount);
                            setQuantity(e.target.value);
                        }}
                        placeholder="Quantity" /> */}
                        </div>
                        <div className="col-span-5 sm:col-span-6 md:col-span-4 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                            <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                        </div>
                        {/* <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
                    {suggestedPrices.map((item, index) => {
                        return <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                            <div
                                className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud'] ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    handleSelectedItem(currentCurrency, index);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                            </div>
                        </div>
                    })}
                </div> */}

                      <div className="col-span-12  md:hidden sm:col-span-6 md:col-span-6 lg:col-span-4  justify-center items-center-">
                            <button
                                className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                onClick={()=>navigate('/zakat-calculator')}
                            >Calculate your Zakat</button>
                        </div>
                        <div className="col-span-12 md:col-span-4 lg:col-span-4 flex justify-center items-center-">
                            <button
                                className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                onClick={handleAddNewProduct}
                            >{donateBtnText ? donateBtnText : 'Quick Donate'}</button>
                        </div>
                    </div>
                </div>
                :
                <div dir={arabic ? "rtl" : 'ltl'} className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg bg-slate-200- h-[400px]- py-2 md:py-4 flex flex-wrap items-center ${className}`}>
                    <div className="basis-full grid grid-cols-12 gap-2">
                        <div className="col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-3 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                            <ReactSelect
                                options={productsList}
                                className='max-h-[48px] w-full pointer-events-none'
                                defaultValue={selectedProduct}
                                isSearchable={false}
                                styles={styles}
                                placeholder='Select Product'
                                onChange={(e) => handleSelectedProduct(e)}
                                // isDisabled

                                readOnly
                            />
                        </div>
                        {/* <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className='[&>*]:!cursor-pointer w-full'
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => {
                            setSelectedRecurring(e);
                        }}
                    />
                </div> */}
                        <div className="col-span-5 sm:col-span-6 md:col-span-3 lg:col-span-3 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                            <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                        </div>
                        {/* <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
                    {suggestedPrices.map((item, index) => {
                        return <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                            <div
                                className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud'] ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    handleSelectedItem(currentCurrency, index);
                                    setProductAmount('');
                                }}
                            >
                                {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                            </div>
                        </div>
                    })}
                </div> */}
                        <div className="col-span-7 sm:col-span-12 md:col-span-3 lg:col-span-3 flex justify-center items-center bg-white rounded-md h-12 px-2  min-[400px]:mb-0" style={styles.shadow}>
                            <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
                            <input
                                value={productAmount}
                                className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                                type="number"
                                // onChange={(e) => {
                                //     setProductAmount(e.target.value);
                                //     setSelectedPrice(null);
                                // }}
                                placeholder="Enter amount" />
                            {/* <input
                        value={quantity}
                        className="w-full p-2 rounded-lg h-full text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={(e) => {

                            if(e.target.value === '0') return;
                            if (!e.target.value) {
                                setProductAmount('');
                                setNumberOfFasts(e.target.value);
                                return;
                            }

                            let price = selectedProduct[`${currentCurrency.toLowerCase()}`];
                            let amount = formatNumberWithTwoDecimals(e.target.value * price)

                            setProductAmount(amount);
                            setQuantity(e.target.value);
                        }}
                        placeholder="Quantity" /> */}
                        </div>
                        <div className="col-span-12 md:col-span-3 lg:col-span-3 flex justify-center items-center-">
                            <button
                                className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                                onClick={handleAddNewProduct}
                            >{donateBtnText ? donateBtnText : 'Quick Donate'}</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default ZakatAlFitrWidget;
