import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CustomButton from './button';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const MultiProductsBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const {
    title,
    description,
    image,
    currency,
    multiProducts,
    arabic = false,
    // staticLink,
    buttonLable,
    alt,
    linkBtnClasses,
    selectOptionClasses,
    showMultiProductsOnRow,
    className,
    lang = 'en',
  } = props;
  const [selectedProduct, setSelectedProduct] = useState();
  const [showMore, setShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = (currency, product) => {
    if (product.quantity) {
      return ShowCurrencies(currency, product[currency.toLowerCase()] * product.quantity, false);
    } else {
      return ShowCurrencies(currency, product[currency.toLowerCase()], false);
    }
  };
  const handleHeight = () => {
    setShowMore(!showMore);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log("multiproduct on multiproducts===", multiProducts,multiProducts[0])
    multiProducts && setSelectedProduct(multiProducts[0]);
    multiProducts && ref.current.clientHeight > 79 && setIsOverflow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiProducts]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white ${className || ''}`} style={{ height: '100%' }}>
      <div
        className="overflow-hidden img-hover-zoom rounded-md w-full sm:h-[169px]- md:w-[210px]- md:h-[210px]- lg:w-[301px]- lg:h-[301px]- xl:w-[280px]- xl:h-[280px]- 2xl:w-[344px]- 2xl:h-[344px]- tooltip [&>span]:!block max-w-full"
        onClick={() => navigate(`/appeals/${multiProducts[0]?.name.replace(/\s+/g, '-').toLowerCase()}`)}
      >
        {image ? (
          image ? (
            <LazyLoadImage
              alt={alt ? alt : title}
              effect="blur"
              width={`100%`}
              delayTime={500}
              className="rounded cursor-pointer block"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/general/logo-replace.png';
              }}
              src={image} // use normal <img> attributes as props
            />
          ) : (
            <img className="w-full" src="/images/general/logo-replace.png" alt="" />
          )
        ) : (
          <Skeleton height={344} />
        )}
      </div>
      <p
        className={`text-[#00a3da] cursor-pointer flex justify-center items-center text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center h-[42px] sm:h-[40px] lg:h-[56px] my-2 leading-4 md:leading-none uppercase ${
          arabic ? 'font-[AlmariMedium] font-bold' : 'font-medium'
        }`}
        onClick={() => navigate(`/appeals/${multiProducts[0]?.name.replace(/\s+/g, '-').toLowerCase()}`)}
      >
        {title || ''}
      </p>
      <div className="relative text-center">
        {/* <div className={`transition-all duration-300 text-center my-2 relative overflow-hidden max-h-[65px] md:max-h-[78px] ${description.split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}> */}
        <div
          className={`transition-all duration-300 text-center my-2 relative overflow-hidden ${
            showMore ? 'h-auto mb-4' : 'max-h-[65px] md:max-h-[78px]'
          } ${description.split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}
        >
          <p
            ref={ref}
            className={`text-stone-500 text-sm sm:text-sm md:text-base lg:text-base lg:!leading-6- overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[65px] md:min-h-[79px] ${
              arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''
            }`}
          >
            {description || ''}
          </p>
        </div>
        <div className="flex h-8 pb-1">
          {isOverflow && (
            <ChevronDownIcon
              onClick={() => handleHeight()}
              className={`w-6 h-6 text-[#00a3da] transition-all duration-300 cursor-pointer mx-auto ${showMore ? ' transform rotate-180' : ''}`}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-6 gap-2 2xl:gap-2">
        <div className="flex flex-wrap justify-center gap-1- col-span-6">
          {multiProducts &&
            multiProducts.map((item, index) => {
              return (
                item && (
                  <div
                    key={`btns_${index}`}
                    className={`p-1 flex justify-center items-center cursor-pointer basis-full ${
                      showMultiProductsOnRow ? 'sm:basis-1/2' : 'sm:basis-full'
                    } ${selectOptionClasses ? selectOptionClasses : ''}`}
                    onClick={() => setSelectedProduct(item)}
                  >
                    <div
                      key={`btns_${index}`}
                      className={`border border-[#F60362] ${
                        item && selectedProduct && item.name === selectedProduct.name ? 'bg-[#F60362] text-white' : 'text-[#F60362] bg-white'
                      } p-1 min-h-[43px]- rounded-md w-full cursor-pointer flex justify-center items-center`}
                    >
                      {item.anyAmount ? (
                        <span className={`text-xs text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
                          {/* {handleCurrency(currency, '', true)} */}
                          {arabic || lang === 'ar' ? 'أي كمية' : lang === 'fr' ? `N'importe quel montant` : 'Any Amount'}
                        </span>
                      ) : (
                        <span className={`text-xs text-center uppercase ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
                          {item.alternativeName || item.name} {handleCurrency(currency, item)} {<small className={`uppercase`}>{currency}</small>}
                        </span>
                      )}
                    </div>
                  </div>
                )
              );
            })}
        </div>
        <div className="flex justify-center col-span-6">
          <CustomButton
            onClick={() => {
              addProduct({product: selectedProduct, currency: currency})
            }}
            title={buttonLable ? buttonLable : arabic || lang === 'ar' ? 'تبرع الان' : lang === 'fr' ? `FAIRE UN DON MAINTENANT` : 'Donate Now'}
            className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiProductsBox;
