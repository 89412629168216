import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import ReactSelect from "react-select";
// import { Loader } from '../../../../components/general';

const HandleMenu = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { show, onClose, onSaved, selectedMenuItem, currentSection, currentParentId } = props;
    const fileRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [status, setStatus] = useState(false);
    const [file, setFile] = useState();
    const [menuItem, setMenuItem] = useState(
        {
            title: '',
            url: '',
            icon: '',
            order: 0,
            parent_id: 0,
            status: "1",
            id: 0,
        }
    )
    const menuItemSchema = Yup.object().shape({
        title: Yup.string().min(3, 'Should be 3 character long').required('Enter title'),
        url: Yup.string().min(1, 'Should be 1 character long').required('Enter url'),
        order: Yup.number().required('Enter order'),
        status: Yup.boolean()
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSave = (values) => {
        currentSection === 'circle' ? handleAddHighlightMenu(values) : handleAddMenu(values)
    };
    const handleAddMenu = async (values) => {
        setIsLoading(true);
        let req = {
            title: values.title,
            section: currentSection,
            url: values.url,
            parent_id: currentParentId ? currentParentId : null,
            order: values.order,
            status: values.status,
        }
        try {
            await services.createMenu(req);
            toast.success('The menu has been created successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const handleAddHighlightMenu = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('section', currentSection);
        formData.append('url', values.url);
        formData.append('order', values.order);
        formData.append('status', values.status);
        formData.append('icon', file || '');
        try {
            await services.createHighlightMenu(formData, file);
            toast.success('The menu has been created successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const handleUpdate = (values) => {
        console.log("on update=",values,currentSection)
        currentSection === 'circle' ? handleUpdateHighlightMenu(values) : handleUpdateMenu(values)
    };
    const handleUpdateHighlightMenu = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', selectedMenuItem.id);
        formData.append('title', values.title);
        formData.append('section', currentSection);
        formData.append('url', values.url);
        formData.append('order', values.order);
        formData.append('status', values.status ? 1 : 0);
        file && formData.append('icon', file || '');
        try {
            await services.updateHighlightMenu(selectedMenuItem.id,formData, file);
            toast.success('The menu has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
        }
    };
    const handleUpdateMenu = async (values) => {
        setIsLoading(true);
        let req = {
            id: selectedMenuItem.id,
            title: values.title,
            section: selectedMenuItem.section,
            url: values.url,
            parent_id: selectedMenuItem.parent_id,
            order: values.order,
            status: values.status === 'true' ? 1 : 0,
        }
        try {
            const response = await services.updateMenu(selectedMenuItem.id, req);
            toast.success('The menu has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response update menu', response);
        } catch (error) {
            // toast.error('Something went wrong!');
            toast.error(error.response.data.message || 'Something went wrong!');
            setIsLoading(false);
            console.error('error========', error);
            console.error('error========||=', error.response.data);
        }
    };
    const closeModal = () => {
        setMenuItem({
            ...menuItem,
            title: '',
            url: '',
            order: 0,
            parent_id: 0,
            status: "1",
            id: 0,
        });
        onClose();
    }
    const handleSubmit = (values) => {
        selectedMenuItem ? handleUpdate(values) : handleSave(values)
    }
    const handleIconChange = (e) => {
        console.log("e.target.files[0]=", e.target.files[0])
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (!(tempFile.type === 'image/jpeg' || tempFile.type === 'image/png')) {
                toast.warning('Please upload a jpg/png file');
                fileRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                fileRef.current.value = '';
                return false;

            }
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setMenuItem({ ...menuItem, icon: objectUrl });
            console.log("name,file", tempFile, objectUrl,)
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        show && console.log("show modal", selectedMenuItem);
        if (selectedMenuItem && show) {
            setMenuItem(selectedMenuItem);
            // setStatus(selectedMenuItem.status)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={selectedMenuItem && show ? 'Edit Menu' : 'Add New Menu'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    title: menuItem.title,
                    url: menuItem.url,
                    status: menuItem.status,
                    order: menuItem.order,
                }}
                validationSchema={menuItemSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={(values) => handleSubmit(values)}>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3 mt-3 md:mt-0 gap-2 md:gap-3 max-h-[400px] md:max-h-[590px] overflow-y-auto">
                            {currentSection === 'circle' && <div className="flex col-span-12 flex-col min-h-[76px] relative">
                                <div className="absolute bottom-1 right-1 rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                                    <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <input ref={fileRef} type="file" accept="image/jpeg, image/png" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleIconChange(e)} />
                                </div>
                                <div className="flex items-center w-full md:max-h-[100px] overflow-hidden border border-stone-200 rounded-md">
                                    <img src={
                                        menuItem.icon
                                            ? menuItem.icon
                                            : file
                                                ? URL.createObjectURL(file)
                                                : `/images/general/logo-replace.png`
                                    } alt="slider" className='w-full- w-auto h-auto rounded md:max-h-[100px] mx-auto' />
                                </div>
                            </div>}
                            <div className="flex col-span-12 md:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="title">Title</label>
                                <input
                                    name='title'
                                    type="text"
                                    value={values.title}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setMenuItem({ ...menuItem, title: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.title && touched.title) &&
                                    <small id='title-error' className="text-[#f60362] text-xs">
                                        {errors.title}
                                    </small>
                                }
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="url">URL</label>
                                <input
                                    name='url'
                                    type="text"
                                    value={values.url}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setMenuItem({ ...menuItem, url: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.url && touched.url) &&
                                    <small id='url-error' className="text-[#f60362] text-xs">
                                        {errors.url}
                                    </small>}
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="status">Status</label>
                                <select
                                    name="status"
                                    value={values.status}
                                    className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                    onChange={(e) => {
                                        handleChange(e)
                                        setMenuItem({ ...menuItem, status: e.target.value });
                                    }}
                                >
                                    <option value={true} className='cursor-pointer'>
                                        Active
                                    </option>
                                    <option value={false} className='cursor-pointer'>
                                        Deactive
                                    </option>
                                </select>
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[76px]">
                                <label className="text-sm text-stone-500" htmlFor="order">Order</label>
                                <input
                                    name='order'
                                    type="number"
                                    value={values.order}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setMenuItem({ ...menuItem, order: e.target.value });
                                    }}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.order && touched.order) &&
                                    <small id='order-error' className="text-[#f60362] text-xs">
                                        {errors.order}
                                    </small>
                                }
                            </div>
                        </div>
                        <div className="flex w-full justify-end pt-4">
                            <button className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2' onClick={() => closeModal()}>Cancel</button>
                            <button
                                disabled={isLoading}
                                type="submit"
                                className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            // onClick={() => selectedMenuItem ? handleUpdate() : handleSave()}
                            >
                                {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                </>) : (
                                    selectedMenuItem && show ? 'Update' : 'Save'
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
export default HandleMenu;