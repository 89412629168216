const foodFaqs = [
    {
        id: 0,
        question: 'What is food donation?',
        answer: `Food donation is the act of providing food to individuals or organisations in need. It involves contributing monetary funds to combat hunger and nourish the less fortunate.`,
    },
    {
        id: 1,
        question: 'Can I make monetary donations to food charity organisations?',
        answer: `<div>Absolutely! <a class='text-[#00a3da]' href='/'>MATW Project</a>, accept monetary donations. These funds are used to purchase bulk food items and cover operational expenses, allowing flexibility in acquiring the most needed and nutritious foods.</div>`,
    },
    {
        id: 2,
        question: 'What is the importance of food donation in Islam?',
        answer: `In Islam, feeding the hungry and assisting the needy is highly regarded. It fulfils the duty of caring for others and offers an opportunity to earn rewards and seek the pleasure of Allah.`,
    },
    {
        id: 3,
        question: 'How can I donate food in accordance with Islamic principles?',
        answer: `To donate food according to Islamic principles, ensure it is halal and of good quality. Non-perishable items like canned goods, rice & others are commonly accepted. Donating fresh and healthy produce is also encouraged whenever possible.`,
    },
    {
        id: 4,
        question: 'How can I donate food to the poor through the MATW Project?',
        answer: `To donate food to the poor through MATW, visit our official website(matwproject.org) and navigate to the <a class='text-[#00a3da]' href='/food-and-water-aid/food-aid'>food aid donation</a> section. Detailed instructions, including drop-off locations and collection drives, will be provided.`,
    },
    {
        id: 5,
        question: 'Does MATW Project have a food donation program for schools?',
        answer: `The availability of school-based food donation programs may vary. Please visit MATW Project's website or contact us directly to inquire about our initiatives related to school donations or support for school meal programs.`,
    },
];
const waterFaqs = [
    {
        id: 0,
        question: 'What is water donation?',
        answer: `Water donation refers to contributing resources, such as money, supplies, or infrastructure, with the specific purpose of providing access to clean and safe water to communities in need.`,
    },
    {
        id: 1,
        question: 'Why is water donation important?',
        answer: `Water donation is important because it addresses the urgent need for clean water in communities that lack access to this essential resource. It helps prevent waterborne diseases, improves health and hygiene, and empowers communities to thrive.`,
    },
    {
        id: 2,
        question: 'What does a water charity organisation do?',
        answer: `A water charity organisation such as MATW Project is committed to providing access to clean and safe water solutions to communities in need. MATW Project collects donations for initiatives aimed at addressing water scarcity and improving access to clean water sources.`,
    },
    {
        id: 3,
        question: 'What is the significance of water aid donations in Islam?',
        answer: `Water aid donations hold great significance in Islam as they align with the teachings of compassion, empathy, and social responsibility. It promotes the concept of brotherhood in Islam by helping to provide clean water to communities in need, improving health and hygiene.`,
    },
    {
        id: 4,
        question: 'How does water scarcity affect developing countries?',
        answer: `Water scarcity in developing countries has wide-ranging impacts. It hinders agricultural productivity, leading to food insecurity. Lack of clean water and sanitation facilities also contributes to the spread of diseases, affecting overall community health.`,
    },
    {
        id: 5,
        question: 'What is the difference between a one-time donation and a recurring donation?',
        answer: `A one-time donation is a single contribution, while a recurring donation involves regular contributions made at set intervals (monthly, quarterly, or annually). Recurring donations provide sustained support to charity projects and allow to plan for the long term.`,
    },
    {
        id: 6,
        question: 'Can I donate water to international communities?',
        answer: `Yes, it is possible to donate water to international communities. MATW operates worldwide to implement water projects in various countries facing water scarcity. <a class='text-[#00a3da]' href='/donate-now/make-a-donation'>Donate now</a>`,
    },
    {
        id: 7,
        question: 'Can I donate water to areas affected by natural disasters or emergencies?',
        answer: `Yes, water donations are particularly crucial in areas affected by natural disasters or emergencies. MATW provides you with the opportunity to donate towards water initiatives and emergency relief efforts to provide immediate access to safe drinking water in critical situations.`,
    },
    {
        id: 8,
        question: 'What kind of water wells does MATW build?',
        answer: `At MATW Project, we’re solution driven and ensure that our solutions cater to each community's needs. We build hand-dug wells, drilled wells and provide water purification systems in difficult to reach areas.`,
    },
    {
        id: 9,
        question: 'What do I get when I donate a water well?',
        answer: `At MATW Project, we’re 100% committed to delivering a transparent experience. When a donor invests in a Sadaqah Jariyah project, we provide updates to keep our donors informed of the progress at different stages. The final report includes images of the project throughout it’s stages and in some cases, a video.`,
    },
    {
        id: 10,
        question: 'How long does it take to build a water well?',
        answer: `Depending on the <a class='text-[#00a3da]' href='/sadaqah-jariyah/build-a-water-well'>water well</a> and the country in which it is being built, your well could take up to one year from donation to delivery.`,
    },
];

export {foodFaqs,waterFaqs}