import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { foodFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react';
import feedImg from '../../images/landings/food-aid/feed-orphan.jpg';
// import payZakatImg from '../../images/landings/food-aid/pay-zakat.jpg';

import foodSlider1 from '../../images/landings/food-aid/food-slider1.webp';
import foodSlider2 from '../../images/landings/food-aid/food-slider2.webp';
import foodSlider3 from '../../images/landings/food-aid/food-slider3.webp';
import foodSlider4 from '../../images/landings/food-aid/food-slider4.webp';
import foodSlider5 from '../../images/landings/food-aid/food-slider5.webp';
import banner from '../../images/landings/food-aid/Food-Aid-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/landings/food-aid/Food-Aid-Generic-Hero-Banner-Mobile.jpg';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
const images = [foodSlider1, foodSlider2, foodSlider3, foodSlider4, foodSlider5];

const FoodAidUATLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => getProducts(state, 'Food and Water Aid', 'Food Aid'));
  // const ramadanProducts = useSelector((state) => getProducts(state, 'Ramadan', 'General'));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [sliderRefImages, instanceRefImages] = useKeenSlider(
    {
      mode: 'free-snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlideImages(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      slides: {
        origin: 'auto',
        perView: 1,
        spacing: 8,
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    let p = products.subCategory.products;
    // console.log("producfv=", ramadanProducts);
    // let r = ramadanProducts.subCategory.products;
    // let fifthProduct = r.find(i => i.creator === 'RM24-FA-009');
    // if (fifthProduct) {
    //     tempProductsList[5] = fifthProduct;
    // }
    p.forEach(item => {
      // if (item.creator === 'RM24-FA-009') {
      //     tempProductsList[5] = item;
      // }
      if (item.creator === 'FST-DEL-F150-2023-01-010') {
        tempProductsList[6] = item; // Orphan Feast For 150
      }
      if (item.creator === 'MKD-GEN-MFA-2023-01-118') {
        tempProductsList[7] = item; // Food Aid
      }
      if (item.creator === 'FWD-DIS-AR-GN-002') {
        tempProductsList[8] = item; // Feed 10 Hungry People
      }
      if (item.creator === 'FWD-DIS-FPY-2023-01-045') {
        tempProductsList[9] = item; // Food Pack For Yemen
      }
      if (item.creator === 'FWD-DIS-FPP-2023-01-046') {
        tempProductsList[10] = item; // // Food Pack For Pakistan
      }
      if (item.creator === 'FWD-DIS-FPM-2023-01-044') {
        tempProductsList[11] = item; // Food Pack For Middle East
      }
      if (item.creator === 'FWD-DIS-BMM-2023-01-049') {
        tempProductsList[12] = item; // Baby Milk For 4 Weeks
      }
      if (item.creator === 'FWD-DIS-BMY-2023-01-050') {
        tempProductsList[13] = item; // Baby Milk For 52 Weeks
      }
      if (item.creator === 'FWD-DIS-RBG-2023-01-052') {
        tempProductsList[14] = item; // Rice Bags
      }

      if (item.creator === 'FWD-DIS-DBD-2023-01-051') {
        tempProductsList[15] = item; // Bread
      }
      if (item.creator === 'FWD-DIS-BMW-2023-01-048') {
        tempProductsList[16] = item; // Baby Milk For 1 Week
      }
    });
    setFoodAidProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['foodAndWaterAid']['foodAid']['title']}`}</title>
          <meta name="title" content={`${seo['foodAndWaterAid']['foodAid']['title']}`} />
          <meta name="description" content={`${seo['foodAndWaterAid']['foodAid']['description']}`} />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold">Make a Donation</div>
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center">
            <CrisisWidget
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              fixPrice={false}
              defaultProduct={{
                creator: 'MKD-GEN-MFA-2023-01-118',
                label: 'Food Aid',
                value: 103,
              }}
            />
          </div>
          <div className="w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center">
            <ZakatWidget
              hasSuggestPrice
              defaultProduct={{
                creator: 'MKD-GEN-MFA-2023-01-118',
                label: 'Food Aid',
                value: 103,
              }}
            />
          </div>
        </section>
      </section>
      <OurPromiseSection />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[200px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
            >
              <div className="leading-[50px]  w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-gotcha">
                The Prophet PBUH said:
              </div>
              <p className="text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                “If a Muslim feeds a hungry Muslim, Allah will feed him from the fruits of Paradise on the Day of Judgment.”
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
              <iframe
                className="video pointer-events-none"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                frameBorder={0}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://www.youtube.com/embed/QTNPDosRHi4?controls=0&autoplay=1&loop=1&mute=1&playlist=QTNPDosRHi4`}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="xl:px-10">
          <div className="px-4 md:px-6 mt-2 sm:mt-10  font- leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-left px-2 sm:px-4 font-brandingMedium">
              Whether it be people in countries riddled with conflict, families in disaster-prone areas, or children in impoverished communities -
              hunger doesn’t differentiate. It kills. Your Sadaqah could be the difference between life and death for an orphan in Palestine, a family
              in Lebanon or a widow in Pakistan. As the cost of essential food supplies continues to soar across the globe, our ummah needs to help
              ensure their survival.
              <br />
              <br />
              Our MATW team is working tirelessly in more than 30 countries around the world to reach those most in need. Help us ensure that the most
              vulnerable have what they need. Help us help them survive.
            </div>
            <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
              {foodAidProducts &&
                foodAidProducts.map((item, index) => {
                  return (
                    <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                      <ProductBox
                        product={item}
                        isStaticProduct={item.isStaticProduct || false}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section className="xl:px-20 py-4 md:py-8">
          <div className="mb-4 md:mb-12">
            <div className="flex justify-center">
              <div className="text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                Don’t let hunger win.
              </div>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-4 md:mt-8 leading-5">
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] ">
                From sustainable solutions to help lift communities out of poverty, to food packs and urgent meal deliveries, our global projects team
                is committed to finding the best solutions in the areas we work to ensure we’re reaching, and feeding as many people as possible with
                your Sadaqah and Zakat.
              </p>
            </div>
          </div>
        </section>

        <section className="px-4 xl:px-20 mt-4 md:mt-10 bg-white">
          <div className="flex gap-x-10 flex-wrap sm:flex-nowrap">
            <div className="basis-full sm:basis-1/2">
              <img src={feedImg} alt="feed-an-orphan" />
            </div>
            <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
              <h1 className="text-[#253B7E] text-[24px] sm:text-[30px] md:text-[45px] leading-[55px] font-brandingBold">Feed An Orphan</h1>
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] ">
                When disaster & conflict strike, vulnerable children and orphans are the first to suffer. At least 40 million children around the
                world are severely food insecure. Help us provide nutritious meals for them.
              </p>
              <button
                onClick={() => navigate('/orphans/orphan-feast')}
                className="bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px] mt-3 font-bold"
              >
                Feed An Orphan
              </button>
            </div>
          </div>

        </section>

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="py-4 md:py-10">
          <div className="md:container md:mx-auto flex justify-center flex-wrap mt-6 md:mt-12">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item} alt={`carousel_images${index}`} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 pb-6">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}

        {/* -------------------------------------FAQS--------------------------------- */}
        <section className="px-4 xl:px-20 mt-4 md:mt-10 bg-white">
          <h1 className="text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold">
            {/* Project Noor donations  */}
            Food Aid Donations <span className="text-[#F60362]">FAQ’s</span>
          </h1>
          <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16 lg:gap-24">
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[0].question}</h5>
              <p className="text-gray-600 font-brandingMedium">{foodFaqs[0].answer}</p>
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[1].question}</h5>
              {/* <p className="text-gray-600 font-brandingMedium">
                                {foodFaqs[1].answer}
                            </p> */}
              <div dangerouslySetInnerHTML={{ __html: foodFaqs[1].answer }} />
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="text-lg lg:text-2xl text-[#253B7E] font-brandingBold">{foodFaqs[2].question}</h5>
              <p className="text-gray-600 font-brandingMedium">{foodFaqs[2].answer}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {foodFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                      {item.answer}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default FoodAidUATLandingPage;
