const winterFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact and continues to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief, Sadaqah Jariyah is considered an investment in good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'Will I receive any reports for my donation?',
    answer: `Your contribution will be directed to our Sadaqah Jariyah Fund, where it will be allocated based on the prevailing needs. This may involve the construction of water wells in areas with the greatest need, the maintenance of mosques and water wells, or enhancements to our orphan care centers. Our team will provide periodic impact reports to keep our donors informed about how their contributions are making a difference.`,
  },
  {
    id: 2,
    question: 'Are any of these projects applicable for Zakat?',
    answer: `Please note that some of our projects may qualify for Zakat, while others may not meet the criteria. If you intend to fulfill your Zakat obligation, we recommend directing your donation specifically to our Zakat projects, ensuring that your contribution is allocated appropriately.`,
  },
  {
    id: 3,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `We accept multiple payment methods to make the donation process convenient for supporters.
                  You can use <strong>Credit Cards</strong> or <strong>Debit Cards</strong> from major providers, as well as online payment
                  platforms such as <strong>Gpay</strong>, <strong>PayPal</strong>, and <strong>Apple Pay</strong> that are secure and trusted.
                  The MATW Project ensures that your financial information remains confidential during the donation process.`,
  },
  {
    id: 4,
    question: 'What should I donate towards if I cannot choose?',
    answer: `Our charity partners with local medical professionals and organizations to identify individuals needing eye care services.
                  Donations fund eye examinations, surgeries, medications, and eyeglasses.
                  These interventions address a range of eye conditions, from refractive errors to cataracts and more.`,
  },
];
const faqs = [
  {
    question: "Why is providing relief during winter important?",
    answers: "Winter relief is crucial because extreme cold weather poses serious health risks, especially for vulnerable populations. Adequate aid helps protect individuals from cold-related illnesses, provides warmth, and ensures their well-being."
  },
  {
    question: "What is provided as part of winter aid?",
    answers: "Winter aid typically includes food, shelter, warm clothing, blankets, heating materials, winter kit for emergency location and other essential supplies to help people withstand cold temperatures."
  },
  {
    question: "Where are you distributing winter relief?",
    answers: `We are distributing winter relief in areas with harsh winter conditions, focusing on regions where vulnerable communities are at risk. 
    • Middle East (Palestine/Gaza, Lebanon, Jordon, Yemen) • Africa (Libya, Morocco) • South East Asia (Afghanistan, Pakistan, Bangladesh)`
  },
  {
    question: `Will you be distributing winter relief in Palestine?`,
    answers: `Yes, we are actively working to provide winter relief in Palestine to support those in need during the colder months.`
  },
  {
    question: `Will aid be reaching those affected by recent earthquakes?`,
    answers: `Yes, our efforts extend to areas affected by recent earthquakes, ensuring that winter aid reaches those facing additional challenges.`
  },
  {
    question: `Is the aid the same in each country?`,
    answers: `While the core components remain consistent, aid packages may be adapted based on specific regional needs and climate conditions.`
  },
  {
    question: `Can I donate my zakat towards the winter program? (specify products which are zakat eligible)`,
    answers: `Yes, you can donate your zakat towards the winter program. Zakat-eligible products may include warm clothing, blankets, and other essentials that fulfill the criteria of zakat.`
  },
  {
    question: `Who will be receiving the aid? (beneficiaries targeted)`,
    answers: `The aid is targeted towards vulnerable populations, including refugees, displaced persons, and communities facing economic hardships, ensuring it reaches those most in need.`
  },
  {
    question: `What is the living condition of those requiring winter relief?`,
    answers: `Those requiring winter relief often live in precarious conditions, lacking proper shelter and resources to cope with the harsh winter weather.`
  },
  {
    question: `How can I help more?`,
    answers: `You can help by making a donation, spreading awareness (follow and share MATW social media page), or volunteering. Your support contributes to providing essential relief to those in need.`
  },
  {
    question: `Can I volunteer in the field?`,
    answers: `Yes, MATW welcome volunteers. You can register yourself as a volunteer from MATW website, please check the below mentioned link for more details 
    https://matwproject.org/Volunteer/`
  },

];
export { winterFaqs, faqs };
