import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import Legacy1 from '../../../images/home/legacy1.png';
import Legacy2 from '../../../images/home/legacy2.png';
import Legacy3 from '../../../images/home/legacy3.png';
import Legacy4 from '../../../images/home/legacy4.png';
import { useNavigate } from 'react-router-dom';

const AliBanatLegacy = (props) => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 1,
        spacing: 10,
      },
      spacing: 16,
      loop: false,
      controls: true,
      slideChanged() {
        console.log('slide changed')
      },
    },
  )
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#093686] min-h-[700px] ">
      <div className="md:container md:mx-auto py-16 md:px-4 lg:px-30 relative overflow-hidden">
        <p className='text-center text-[32px] sm:text-[60px] text-white mb-10 font-gotcha cursor-pointer' onClick={() => navigate('/ali-banat')}>
          Ali Banat's Legacy
        </p>
        {/* For large devices only */}
        <div className='md:block  hidden px-4 relative my-6'>
          <div ref={sliderRef} className="rounded keen-slider ">
            <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
              <div className='flex-1 pl-3 pr-20 md:pr-3 lg:pr-20'>
                <div className='text-[40px] font-bold leading-9 mb-4 text-[#093484]'>
                  Remember our founder, Ali Banat?
                </div>
                <div className='text-[20px] text-[#78716C] font-brandingMedium'>
                  Ali was and still is the perfect example that time is a blessing. At 27 years old Ali was diagnosed with cancer.
                  For some, this diagnosis would be terrifying but for Ali, it was a gift.
                </div>
              </div>
              <div className=' flex justify-left flex-1 '> <img alt="" src="https://cdn.matwproject.org/static/media/legacy11.81a5d3462eb095e5e34f.png
" /></div>
            </div>
            <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
              <div className='flex-1 pl-3 pr-20 md:pr-3 lg:pr-20'>
                <div className='text-[40px] font-bold leading-9 mb-4 text-[#093484]'>
                  Journey Towards Akhirah: <div>A Leap of Faith</div>
                </div>
                <div className='text-[20px] text-[#78716C] font-brandingMedium'>
                  He took a long and hard look at his life and decided that everything he had accumulated in his short time on this earth wasn’t anything that he
                  could present to Allah SWT.
                  He sold everything he had from the fast cars to the luxury items, and booked the first flight to Togo in Africa and invested in his Akhirah.
                </div>
              </div>
              <div className=' flex justify-left flex-1 '> <img alt="" src='https://cdn.matwproject.org/static/media/legacy12.753a665c4fe79de4b973.png
' /></div>
            </div>
            <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
              <div className='flex-1 pl-3 pr-20 md:pr-3 lg:pr-20'>
                <div className='text-[40px] font-bold leading-9 mb-4 text-[#093484]'>
                  MATW: Serving the Ummah Globally
                </div>
                <div className='text-[20px] text-[#78716C] font-brandingMedium'>
                  Fast forward eight years, Muslims Around The World (MATW) now serves the ummah on a global scale.
                </div>
              </div>
              <div className=' flex justify-left flex-1 '> <img alt="" src="https://cdn.matwproject.org/static/media/legacy13.db02f131140dccb74bfa.png
" /></div>
            </div>
            <div className="px-20 keen-slider__slide bg-white p-4 min-h-[550px] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex justify-center items-center">
              <div className='flex-1 pl-3 pr-20 md:pr-3 lg:pr-20'>
                <div className='text-[40px] font-bold leading-9 mb-4 text-[#093484]'>
                  Transformative Journeys: The Power of Your Donation
                </div>
                <div className='text-[20px] text-[#78716C] font-brandingMedium'>
                  At MATW,  we hold a firm belief in the strength of collective compassion. Your donation marks the initiation of a transformative
                  journey that will touch lives and
                  leave behind a legacy of positive change.
                </div>
              </div>
              <div className=' flex justify-left flex-1 '> <img alt="" src="https://cdn.matwproject.org/static/media/legacy14.4db13bce9dcade936ae7.png
" /></div>
            </div>
          </div>
          <BsFillArrowLeftSquareFill size={35}
            onClick={() => {
              console.log(instanceRef)
              instanceRef.current.prev();
            }}
            className={'text-[#093484] arrow text-xl absolute left-[35px] top-[240px]'} />
          <BsFillArrowRightSquareFill size={35} onClick={() => {
            console.log(instanceRef)
            instanceRef.current.next();
          }}
            className={` text-[#093484] arrow text-xl absolute right-[35px] top-[240px]`} />
        </div>
        {/* For small devices */}
        <div className='px-16 md:hidden'>
          <div className='  flex border-[10px]  border-t-0 border-r-0 rounded-bl-[20px] min-h-[150px] '>
            <div className='mr-4 -ml-12 -mt-3 max-w-[375px] min-w-[100px]'> <img alt="" src={Legacy1} className='' /></div>
            <div className='mb-6 flex flex-col text-white  '>
              <div className='text-[#F60362]'>Oct 2015</div>
              <div className='mt-1 flex items-start justify-start'>
                <span className='text-[64px] mr-4 leading-[48px]'>1</span>
                <div className='text-white text-[14px] leading-4'>
                  <div className='text-[16px] mb-2 leading-4'>Remember our founder, Ali Banat?</div>
                  At 27 years old Ali was diagnosed with cancer. For some, this diagnosis would be terrifying but for Ali, it was a gift.</div>
              </div>
            </div>
          </div>
          <div className='-mt-[10px] -mr-[11px]  flex border-[10px] border-t-0 border-l-0 rounded-tr-[18px] rounded-br-[20px] min-h-[200px]'>
            <div className='flex mt-12 -mr-[44px] justify-between'>
              <div className=' sm:mt-1 text-[14px] w-2/3 text-white leading-4 pb-4'>
                He realised that everything he had accumulated in his short time on this earth wasn’t anything that he could present to Allah SWT.
                He sold everything he had from the fast cars and luxury items, and travelled to Togo in Africa to invest in his Akhirah.
              </div>
              <div className='flex text-white'>
                <span className='text-[64px] mr-2 leading-[48px]'>2</span>
                <div className='-mr-[15px] -mt-3 max-w-[375px] min-w-[100px]'> <img alt="" src={Legacy2} className='h-[85px] w-[85px]' /></div>
              </div>
            </div>
          </div>
          <div className='-mt-[10px] -ml-[11px]  flex border-[10px] border-t-0 border-r-0 rounded-tl-[18px] rounded-bl-[20px] min-h-[200px]'>
            <div className='mr-4 sm:mr-10 -ml-12 mt-12 max-w-[375px] min-w-[100px] '> <img alt="" src={Legacy3} className='h-[100px] w-[100px]' /></div>
            <div className='flex mt-12 flex-col text-white  pb-4'>
              <div className='text-[#F60362]'>2023</div>
              <div className='mt-1 flex items-start justify-start'>
                <span className='text-[64px] mr-4 leading-[48px]'>3</span>
                <div className='text-white text-[14px] leading-4 w-2/3'>
                  Fast forward eight years, Muslims Around The World (MATW) now serves the ummah on a global scale.</div>
              </div>
            </div>
          </div>
          <div className={`relative -mt-[10px] -mr-[11px]  flex   rounded-tr-[18px] rounded-br-[20px] min-h-[150px]
                    before:content-['']  before:w-[10px]  before:h-[40px] before:bg-[#e5e7eb] before:absolute
                    before:-z-[1px] before:right-1 before:border before:rounded-tr-[25px]
                    `}>
            <div className='flex mt-12 -mr-[30px] justify-between'>
              <div className='mt-2 text-[14px] w-2/3 text-white leading-3'>
                At MATW, we hold a firm belief in the strength of collective compassion. Your donation marks the
                initiation of a transformative journey that will touch lives and
                leave behind a legacy of positive change.
              </div>
              <div className='flex text-white'>
                <span className='text-[64px] mr-4 leading-[48px]'>4</span>
                <div className='max-w-[375px] min-w-[100px] -mr-[15px] -mt-3'> <img alt="" src={Legacy4} className='h-[85px] w-[85px]' /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AliBanatLegacy;
