import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import AppealFundraiserProductBox from './appealFundraiserProductBox';
import { selectSelectedCurrency } from '../../../store/user';

const AppealWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { appealProducts, className, usedOnTab } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(min-width: 320px)': {
        slides: { origin: 'center', perView: 1.2, spacing: 10 },
      range: { min: 0, max: 2 },
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 992px)': {
        slides: { perView: 3, spacing: 16, origin: 'center' },
      },
    },
    slides: {
      origin: 'center',
      // perView: 4,
      // spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section
      className={`${className ? className : 'bg-LighterBlue'} w-full overflow-hidden- px-4 md:px-0 ${usedOnTab ? 'py-2 md:py-4' : 'py-4 md:py-12'}`}
    >
      <div className="md:container w-full mx-auto">
        <div className="w-full justify-center items-center flex pb-4 md:pb-8">
          <h2 className={`text-DarkerBlue font-brandingBold ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'}`}>MATW Appeals</h2>
        </div>
        <div className="w-full hidden sm:flex flex-wrap justify-center items-center relative">
          {appealProducts.map((item, index) => {
            return (
              <div
                key={`active_carousel_${index}`}
                className="basis-1/3 flex justify-center px-2 py-4 
"
              >
                <AppealFundraiserProductBox
                  campaign={item.campaign || 'palestine'}
                  goal={item.goal}
                  subTitle="Orphan Education"
                  product={item}
                  currency={selectedCurrency ? selectedCurrency : 'AUD'}
                />
              </div>
            );
          })}
        </div>
        <div
          className="w-full sm:hidden flex-wrap justify-center items-center relative border 
"
        >
          {appealProducts.length > 1 && (
            <div className="hidden md:flex ">
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center  items-center translate-y-1/2 w-8 h-8 top-[47%] -left-[0px] lg:left-[0px] xl:left-[8px] 2xl:left-[4px] z-10 rounded-full ${
                    currentSlide === 0 ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]' : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                  }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <LiaAngleLeftSolid size={25} />
                </div>
              )}
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[47%] -right-[0px] lg:right-[0px] xl:right-[8px] z-10 rounded-full ${
                    currentSlide === instanceRef.current.track.details.slides.length
                      ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]'
                      : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                  }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <LiaAngleRightSolid size={25} />
                </div>
              )}
            </div>
          )}
          <div ref={sliderRef} className="keen-slider !w-[94%] min-[1280px]:!w-[94%] mx-auto">
            {appealProducts.map((item, index) => {
              return (
                <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                  <div className="w-full max-w-[300px]-">
                    <AppealFundraiserProductBox
                      campaign={item.campaign || 'palestine'}
                      goal={item.goal}
                      subTitle="Orphan Education"
                      product={item}
                      currency={selectedCurrency ? selectedCurrency : 'AUD'}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {appealProducts.length > 1 && (
            <div className="flex md:hidden py-3 px-2 rounded-md justify-center items-center mx-auto mt-2">
              {loaded && instanceRef.current && (
                <div className="flex justify-center items-center relative w-full">
                  {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={`q${idx}`}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={
                          'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                          (currentSlide === idx ? ' bg-LightBlue hover:opacity-60' : 'bg-[#C3C3C3]')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default AppealWidget;
