import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { getProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const SadaqahAndLillah = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Donate Now', 'Make A Donation'));

    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'MKD-MN-001') {
                setProduct(item);
            }
        });

    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['duaForBreakingFast']['title']}`}</title>
                    <meta name="title" content={`${seo['duaForBreakingFast']['title']}`} />
                    <meta name="description" content={`${seo['duaForBreakingFast']['description']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/general/our-promise-banner-mobile.jpg')] md:bg-[url('../src/images/general/our-promise-banner-head.jpg')]" >
                <div className='leading-[36px] md:hidden'>We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} >
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">

                        <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            {/* <h1 className='font-gotcha text-[30px] sm:text-[50px] leading-[70px] text-center text-[#253B7E]'>
                                Our Promise To You
                            </h1> */}
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    Understanding the Differences Between Sadaqah and Lillah: A Comprehensive Guide
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-brandingMedium'>
                                    Islamic tradition emphasises charity as a pivotal aspect of faith and spirituality. Among the various forms of
                                    giving, Sadaqah and Lillah are two key concepts that, while similar in their charitable essence, have distinct
                                    characteristics and purposes. We delve into the differences between Sadaqah and Lillah, providing insights into
                                    their unique roles within Islamic philanthropy.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Introduction to Islamic Charity
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Before distinguishing between Sadaqah and Lillah, it's essential to understand the broader context of charity in Islam.
                                    Charity, or giving, is a fundamental part of Islam, deeply embedded in its teachings to encourage compassion,
                                    empathy, and support for the less fortunate. The Quran and Hadiths extensively discuss the virtues of giving,
                                    outlining various forms such as Zakat (obligatory charity), Sadaqah (voluntary charity), and Lillah (for the sake
                                    of Allah), each serving a unique purpose in the socio-economic balance and spiritual well-being of the Muslim community.
                                </p>

                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    What is Sadaqah?
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Sadaqah, derived from the Arabic word "sidq" (sincerity), refers to any act of voluntary charity or benevolence. It's
                                    a broad term that encompasses not only financial aid but also includes acts of kindness such as sharing knowledge, offering
                                    a smile, or even removing harm from someone's path. Sadaqah is encouraged at all times, especially during times of
                                    hardship.
                                    <br /><br />
                                    Benefits and Impact <br />
                                    Sadaqah is highly praised for its spiritual and societal benefits. It is seen as a
                                    way to purify one's wealth, earn divine rewards, and protect oneself from calamity.
                                    Sadaqah also plays a crucial role in fostering community solidarity, alleviating poverty,
                                    and spreading happiness and goodwill.
                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    What is Lillah?
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Lillah translates to "for the sake of Allah" and refers to charitable donations made purely for Allah's pleasure,
                                    without seeking any worldly benefit or recognition. Lillah contributions are typically directed towards the construction and maintenance
                                    of mosques, Islamic schools, or to help those in need without any obligation or expectation of repayment.

                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Lillah's Distinctive Nature
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Unlike Sadaqah, which can be given to anyone in need, Lillah is often earmarked for specific religious or
                                    charitable projects. It is a form of Sadaqah that carries the intention of dedicating the reward solely to Allah,
                                    making it a profound expression of faith and devotion.
                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Key Differences Between Sadaqah and Lillah
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    While both Sadaqah and Lillah are forms of charitable giving, they differ in their scope, intentions, and applications.
                                    Sadaqah encompasses a broader
                                    range of charitable acts, both monetary and non-monetary, aimed at anyone in need, while Lillah is more specific to
                                    religious donations without the expectation of worldly returns. The intention behind Lillah is
                                    to seek Allah's pleasure, making it a spiritually focused form of charity.
                                </p>

                            </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Complementary Nature of Sadaqah and Lillah
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-brandingMedium'>
                                    Sadaqah and Lillah, despite their differences, are complementary aspects of Islamic charity. Together,
                                    they embody the holistic approach of Islam towards social welfare and spiritual well-being. By engaging
                                    in both Sadaqah and Lillah, Muslims can fulfill their religious duties, support their communities, and
                                    attain spiritual growth and satisfaction.
                                    <br /><br />
                                    Understanding and practicing these forms of charity can lead to a balanced and fulfilling life, highlighting
                                    the beauty and depth of Islamic teachings on generosity and compassion. As we continue to explore the multifaceted
                                    dimensions of charity in Islam, the differences between Sadaqah and Lillah remind us of the diverse ways we can
                                    contribute to the betterment of society and the upliftment of our spiritual journey.
                                </p>

                            </div>



                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' >
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/ali-banat')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-brandingBold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px] font-brandingMedium'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate('/purpose')} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SadaqahAndLillah