import { useState } from 'react';
import HandleShowAdminCost from '../../../utils/handleAdminCost';
import Select from 'react-select';
import ShowCurrencies from '../../../utils/showCurrencies';
import { useEffect } from 'react';
const styles = {
    control: (base) => ({
        ...base,
        height: '32px',
        // width: '200px',
        cursor: 'pointer',
        fontSize: '16px',
        border: 'none',
        boxShadow: '0px 0px 2px 0px rgb(220, 220, 220)',
        ":hover": {
            boxShadow: '0px 0px 8px 0px rgb(216, 216, 216)',
        }
    }),
    menuList: (base) => ({
        ...base,
        fontSize: '16px',
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        // fontSize: '12px'
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const AdminCosts = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { currency, selectedSubscribe, basketStates, onChange } = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const _adminCost = Number(HandleShowAdminCost(currency));
    const options = [
        { value: 0, label: ShowCurrencies(currency, 0), quantity: 0 },
        { value: _adminCost, label: ShowCurrencies(currency, _adminCost), quantity: 1 },
        { value: _adminCost * 2, label: ShowCurrencies(currency, _adminCost * 2), quantity: 2 },
        { value: _adminCost * 4, label: ShowCurrencies(currency, _adminCost * 4), quantity: 4 },
        { value: _adminCost * 8, label: ShowCurrencies(currency, _adminCost * 8), quantity: 8 },
        { value: _adminCost * 25, label: ShowCurrencies(currency, _adminCost * 25), quantity: 25 },
        { value: _adminCost * 50, label: ShowCurrencies(currency, _adminCost * 50), quantity: 50 },
    ];
    const [selectedAdminCost, setSelectedAdminCost] = useState(options[2]);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start Methods ----------------------------- */
    const handleSelectAdminCost = (_quantity) => {
        options.map(item => {
            if (item.quantity === _quantity) {
                setSelectedAdminCost(item);
                onChange(item.value);
            }
            return item;
        })
    }
    /* -------------------------------------------------------------------------- */

    /* -------------------------------- Useeffect ------------------------------- */
    useEffect(() => {
        currency && handleSelectAdminCost(selectedAdminCost.quantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency])
    useEffect(() => {
        let tempTotalProducts = 0;
        basketStates.products.map(item => {
            item.product ? tempTotalProducts += item.total : tempTotalProducts = 0;
            return null
        });
        // if (!isChanged && !selectedAdminCost.value) {
        //     handleSelectAdminCost(4)
        // } else if (!isChanged) {
        //     console.log("on else=", tempTotalProducts)
        //     if (tempTotalProducts > 0 && tempTotalProducts < 20) {
        //         handleSelectAdminCost(1)
        //     } else if (tempTotalProducts > 19 && tempTotalProducts < 50) {
        //         handleSelectAdminCost(2)
        //     } else if (tempTotalProducts > 49 && tempTotalProducts < 100) {
        //         handleSelectAdminCost(4)
        //     } else if (tempTotalProducts > 99 && tempTotalProducts < 500) {
        //         handleSelectAdminCost(8)
        //     } else if (tempTotalProducts > 499 && tempTotalProducts < 1000) {
        //         handleSelectAdminCost(25)
        //     } else if (tempTotalProducts > 1000) {
        //         handleSelectAdminCost(50)
        //     } else {
        //         handleSelectAdminCost(0)
        //     }
        // }
        if (!isChanged && !selectedAdminCost.value) {
            handleSelectAdminCost(1)
        } else if (!isChanged) {
            handleSelectAdminCost(1)
        }
        if (basketStates.products[0].product) {
            if (!tempTotalProducts) {
                setSelectedAdminCost(options[0]);
                setIsDisabled(true)
            } else {
                if (basketStates.adminCost) {
                    let tempQty = basketStates.adminCost / _adminCost;
                    options.map(item => {
                        if (item.quantity === tempQty) {
                            setSelectedAdminCost(item);
                        }
                        return item;
                    })
                }
                setIsDisabled(false)
            }
        } else {
            setSelectedAdminCost(options[0]);
            setIsDisabled(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basketStates.grandTotal])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="rounded-md flex items-center justify-center mb-4 admin-cost">
            <label className="hover:cursor-pointer text-[#777] text-xs text-right mr-2 sm:text-base flex items-start flex-col sm:flex-row" htmlFor="checkboxDefault">
                <span className='font-medium'>Contribute towards admin costs</span>
                {selectedSubscribe !== 'one-off' && <small className='ml-0 sm:ml-1'>(uncheck this if subscribing by PayPal)</small>}
            </label>
            <Select
                options={options}
                placeholder="Select Admin Cost"
                value={selectedAdminCost}
                styles={styles}
                isDisabled={isDisabled}
                onChange={(e) => {
                    setIsChanged(true);
                    handleSelectAdminCost(e.quantity)
                }}
                isSearchable={false}
            />
        </div>
    );
}

export default AdminCosts;