import React from 'react';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';

const BlessingAndMercy = props => {
  /* ----------------------------- Start variables ---------------------------- */

  /* -------------------------------------------------------------------------- */
  return (
    <section className="mb-0 md:mb-8 px-5 md:px-[40px] py-5 md:py-[40px] bg-[url('../src/images/ali-banat/feel-pattern.png')] bg-[#14a3dc2c]">
      <MaxWidthContainer className="bg-white p-5 md:p-[40px] md-p-[40px] rounded-[10px]">
      <p className="mx-auto mb-5 md:w-10/12 text-[25px] leading-7 md:leading-[50px] md:text-[40px] text-center text-[#093686] font-brandingBold">
          {/* <span className="text-[#F60362] mx-1">Eid Mubarak</span>! Feel your rewards by spreading Eid Joy to an orphan! */}
          Ali Banat’s Vision Was Clear: Build A Legacy To Help Every Needy Muslim Around The World. 
        </p>
        <div className="space-y-3 md:space-y-5 text-[#78716C] font-BrandingMediumItalic md:text-lg md:text-center md:w-10/12 md:mx-auto mt-4">
          <p>
          Ali Banat started the MATW Project as a legacy project that now reaches millions of Muslims all over the world. This Ramadan was no different. Thanks to YOU, our generous donors, Ramadan 2024 has been our biggest yet. You didn’t hesitate when called upon. You made it possible for us to help 
            <span className="text-[#093686] mx-1 font-bold">19,142,706</span> Muslims in some of the poorest parts of the world. We couldn’t be more grateful for your support!
          </p>
          {/* <p>The race for rewards in the final days of this blessed month is worth more than we could possibly imagine. Any of the odd nights could be Laylatul Qadr.</p> */}
          {/* <p>Let’s seek out the night of power together by increasing our Ibadah and our deeds.</p> */}
          {/* <p>Every Ramadan that Ali Banat, our founder, was blessed with before he passed, he spent as though it was his last. Teaching us that every second we have is precious and must be spent seeking Allah’s favor, whether it be through prayer, Quran, dua, or good deeds.</p> */}
          {/* <p className='text-[#253B7E] italic font-bold'>Also, don’t forget to pay your Zakat Al Fitr before Eid prayer!</p> */}
        </div>
        <div className="mx-auto mt-6 w-full flex flex-col md:flex-row justify-center items-center">{props.buttons}</div>
      </MaxWidthContainer>
    </section>
  );
};
export default BlessingAndMercy;
