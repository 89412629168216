import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import your icons
import regularGivingIcon from '../../images/orphans/regular-giving.png';
import appealsIcon from '../../images/orphans/appeals.png';
import moreInfoIcon from '../../images/orphans/more-info.png';
import faqsIcon from '../../images/orphans/faqs.png';

const BottomNav = ({ activeSection, scrollToRef, faqsRef, moreInfoRef, appealsRef }) => {
  const [showBottomNav, setShowBottomNav] = useState(false);
  const navigate = useNavigate();

  // Function to determine if the section is active
  const isActive = section => section === activeSection;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Adjust the scroll position value as needed
        setShowBottomNav(true);
      } else {
        setShowBottomNav(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`bottom-nav ${showBottomNav ? 'flex' : 'hidden'} md:hidden xs:fixed bottom-0 w-full bg-[#E1EFF2] shadow-lg justify-around py-2 z-50`}
      style={{ zIndex: 9999999999 }}
    >
      <button
        className={`flex flex-col items-center ${isActive('regular-giving') ? 'text-gray-400' : 'text-blue-800'}`}
        onClick={() => navigate('/subscription')}
      >
        <img src={regularGivingIcon} alt="Regular Giving" className={`w-6 h-6 ${isActive('regular-giving') ? 'filter grayscale' : ''}`} />
        <span className={`text-xs ${isActive('regular-giving') ? 'text-gray-400' : 'text-blue-800'}`}>Regular Giving</span>
      </button>
      <button
        className={`flex flex-col items-center ${isActive('appeals') ? 'text-gray-400' : 'text-blue-800'}`}
        onClick={() => scrollToRef(appealsRef)}
      >
        <img src={appealsIcon} alt="Appeals" className={`w-6 h-6 ${isActive('appeals') ? 'filter grayscale' : ''}`} />
        <span className={`text-xs ${isActive('appeals') ? 'text-gray-400' : 'text-blue-800'}`}>Appeals</span>
      </button>
      <button
        className={`flex flex-col items-center ${isActive('more-info') ? 'text-gray-400' : 'text-blue-800'}`}
        onClick={() => scrollToRef(moreInfoRef)}
      >
        <img src={moreInfoIcon} alt="More Info" className={`w-6 h-6 ${isActive('more-info') ? 'filter grayscale' : ''}`} />
        <span className={`text-xs ${isActive('more-info') ? 'text-gray-400' : 'text-blue-800'}`}>More Info</span>
      </button>
      <button className={`flex flex-col items-center ${isActive('faqs') ? 'text-gray-400' : 'text-blue-800'}`} onClick={() => scrollToRef(faqsRef)}>
        <img src={faqsIcon} alt="FAQs" className={`w-6 h-6 ${isActive('faqs') ? 'filter grayscale' : ''}`} />
        <span className={`text-xs ${isActive('faqs') ? 'text-gray-400' : 'text-blue-800'}`}>FAQ’s</span>
      </button>
    </div>
  );
};

export default BottomNav;