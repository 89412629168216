import ReactSelect from "react-select";
import usaImg from "../../images/flags/usa.svg";
import ausImg from "../../images/flags/aus.svg";
import canImg from "../../images/flags/can.svg";
import eurImg from "../../images/flags/eu.svg";
// import uaeImg from "../../images/flags/uae.svg";
// import uaeImg from "../../images/flags/uae.svg";
// import indImg from "../../images/flags/ind.svg";
import gbrImg from "../../images/flags/gbr.svg";
import mysImg from "../../images/flags/mys.svg";
import sgpImg from "../../images/flags/sgp.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency,  setSelectedCurrencyInStore } from "../../store/user";
// import HandleLocalStorage, { BASKET_CACHE_KEY } from "../../utils/handleLocalStorage";

const styles = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        "& *": {
            cursor: "pointer"
        }
    })
}

const SelectCurrency = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const dispatch = useDispatch();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { onChangeCurrency, hideFlag, classNamePrefix, className, onMenuOpen, onMenuClose } = props;
    const currencies = [
        { value: 'AUD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={ausImg} width="25px" alt='aus' />AUD</span> },
        { value: 'USD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={usaImg} width="25px" alt='usa' />USD</span> },
        { value: 'GBP', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={gbrImg} width="25px" alt='gbp' />GBP</span> },
        { value: 'CAD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={canImg} width="25px" alt='can' />CAD</span> },
        { value: 'EUR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={eurImg} width="25px" alt='eur' />EUR</span> },
        // { value: 'AED', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={uaeImg} width="25px" alt='aed' />AED</span> },
        // { value: 'IDR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={indImg} width="25px" alt='idr' />IDR</span> },
        { value: 'SGD', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={sgpImg} width="25px" alt='sgp' />SGD</span> },
        { value: 'MYR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={mysImg} width="25px" alt='mys' />MYR</span> },
    ];
    const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);
    // const [basketStates, setBasketStates] = useState({});
    // const [currentCurrency, setCurrentCurrency] = useState();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectCurrency = (currency) => {
        // console.log("on change currency=",currency,currentCurrency)
        setCurrentCurrency(currency);
        onChangeCurrency(currency);
        // let tempBasket = basketStates;
        // tempBasket.currency = currency.value;
        // HandleLocal-Storage(tempBasket)
        dispatch(setSelectedCurrencyInStore(currency.value));
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        let temp = {};
        currencies.map((item) => {
            if (item.value === selectedCurrencyStore) {
                temp = item;
            }
            return null
        })
        // console.log("*temp in select currency*",temp,selectedCurrencyStore)
        temp.value && setCurrentCurrency(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    // useEffect(() => {
    //     const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    //     if (basketStatesFromLocalStorage) {
    //         setBasketStates(basketStatesFromLocalStorage);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <ReactSelect
            options={currencies}
            className={`max-h-[48px] [&>*]:!cursor-pointer w-full [&>div]:!rounded-md ${className}`}
            // defaultValue={currencies[0]}
            classNamePrefix={classNamePrefix ? classNamePrefix : ''}
            value={currentCurrency}
            isSearchable={false}
            styles={styles}
            onChange={(e) => handleSelectCurrency(e)}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
        />
    );
}

export default SelectCurrency;
