import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { orphansFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
// import { useKeenSlider } from 'keen-slider/react';
// import Orphan1 from '../../images/orphans/orphan1.jpeg';
// import Orphan2 from '../../images/orphans/orphan2.jpeg';
// import Orphan3 from '../../images/orphans/orphan3.jpeg';
// import Orphan4 from '../../images/orphans/orphan4.jpeg';
// import Orphan5 from '../../images/orphans/orphan5.jpeg';
// import { useNavigate } from 'react-router-dom';
import { selectProducts } from '../../store/products';
import banner from '../../images/orphans/Orphans-Generic-Hero-Banner-Desktop.jpg';
// import bannerMobile from '../../images/orphans/Orphans-Generic-Hero-Banner-Mobile.jpg';
import { OurPromiseSection } from '../../components/general';
// import OrphanTabsSection from './orphan-tabs';
import BottomNav from './bottomNav';
import OrphanTabs from './orphan-tabs-new';
import OrphanCrisisWidget from './OrphanCrisesWidget';

// const images = [Orphan1, Orphan2, Orphan3, Orphan4, Orphan5];

const OrphansUat = ({ lang = 'en' }) => {
  const bannerFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-FR.webp';
  const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-FR.webp';
  const bannerAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-AR.webp';
  const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-AR.webp';
  const products = useSelector(state => selectProducts(state));
  const [orphanFeastProducts, setOrphanFeastProducts] = useState([0, 0, 0]);
  const [feedPalestinianOrphansProducts, setFeedPalestinianOrphansProducts] = useState([0, 0, 0]);
  const [emergencyOrphanSupportProducts, setEmergencyOrphanSupportProducts] = useState([0]);
  const [supportAnOrphanProducts, setSupportAnOrphanProductsList] = useState([0, 0, 0, 0]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  // const navigate = useNavigate();
  // const [loaded, setLoaded] = useState(false);
  // const [currentSlideImages, setCurrentSlideImages] = useState(0);
  // const [sliderRefImages, instanceRefImages] = useKeenSlider({
  //   mode: 'free-snap',
  //   loop: true,
  //   slideChanged(slider) {
  //     setCurrentSlideImages(slider.track.details.rel);
  //   },
  //   created() {
  //     setLoaded(true);
  //   },
  //   slides: {
  //     origin: 'auto',
  //     perView: 1,
  //     spacing: 8,
  //   },
  // });
  // const [foodAidProducts, setFoodAidProducts] = useState([]);

  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');
  const [showScrollMore, setShowScrollMore] = useState(false);
  const startRef = useRef(null);
  const endRef = useRef(null);

  // const getAllProductss = async () => {
  //   try {
  //     let waterAidProducts = [];
  //     let foodAidProducts = [];
  //     let sadaqahProducts = [];
  //     products.forEach(item => {
  //       item.sub_categories.forEach(inner_item => {
  //         inner_item.products.forEach(inner_item_product => {
  //           if (
  //             ['MA-PAL-GL-4-003', 'MA-PAL-GL-4-006', 'MA-PAL-GL-4-007', 'MA-PAL-GL-4-008', 'MA-PAL-GL-4-010'].includes(inner_item_product.creator)
  //           ) {
  //             waterAidProducts.push(inner_item_product);
  //           }
  //           if (['MA-PAL-GL-4-001', 'MA-PAL-GL-4-002', 'MA-PAL-GL-4-004', 'MA-PAL-GL-4-005'].includes(inner_item_product.creator)) {
  //             foodAidProducts.push(inner_item_product);
  //           }
  //           if (['GD-MP015', 'MKD-WEL-SDQ-2023-01', 'MKD-GEN-MOR-2023-01-119', 'MKD-GEN-MPN-2023-01-124'].includes(inner_item_product.creator)) {
  //             sadaqahProducts.push(inner_item_product);
  //           }
  //         });
  //       });
  //     });

  //     setWaterAidProducts(waterAidProducts);
  //     setFoodAidProducts(foodAidProducts);
  //     // setSadaqahProducts(sadaqahProducts);
  //   } catch (error) {
  //     console.error('error========', error);
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getAllProductss();
  //   };
  //   fetchData();
  // });

  // useEffect(() => {
  //   if (waterAidProducts.length > 0 && foodAidProducts.length > 0) {
  //     setIsLoaded(true);
  //   }
  // }, [waterAidProducts, foodAidProducts]);

  const scrollToRef = ref => {
    if (ref.current) {
      // Calculate offset position above the element
      const offsetPosition = ref.current.offsetTop - 150; // Adjust 100 pixels above the element
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = entries => {
    entries.forEach(entry => {
      console.log(entry);

      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.3,
    });

    if (faqsRef.current) observer.observe(faqsRef.current);
    if (moreInfoRef.current) observer.observe(moreInfoRef.current);
    if (appealsRef.current) observer.observe(appealsRef.current);

    return () => {
      if (faqsRef.current) observer.unobserve(faqsRef.current);
      if (moreInfoRef.current) observer.unobserve(moreInfoRef.current);
      if (appealsRef.current) observer.unobserve(appealsRef.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (startRef.current && endRef.current) {
        const startPosition = startRef.current.getBoundingClientRect().top;
        const endPosition = endRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (startPosition < windowHeight && endPosition > 870) {
          setShowScrollMore(true);
        } else {
          setShowScrollMore(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getAllProducts = () => {
    setIsLoaded(true);
    let orphanFeastProductsList = [];
    let feedPalestinianOrphansProductsList = [];
    let emergencyOrphanSupportProductsList = [];
    let supportAnOrphanProductsList = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'FST-DEL-F150-2023-01-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feast for 150 Orphans',
            });
            orphanFeastProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feast for 50 Orphan',
            });
            orphanFeastProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F25-2023-01-008') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feast for 25 Orphan',
            });
            orphanFeastProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 1 Orphan',
            });
            feedPalestinianOrphansProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 10 Orphans',
            });
            feedPalestinianOrphansProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 20 Orphans',
            });
            feedPalestinianOrphansProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204') {
            emergencyOrphanSupportProductsList[0] = inner_item_product;
          } else if (inner_item.name === 'Education' && inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 20 Orphans',
            });
            supportAnOrphanProductsList[0] = { ...inner_item_product, alternativeName: 'Support 1 Child', quantity: 50 };
            supportAnOrphanProductsList[1] = { ...inner_item_product, alternativeName: 'Support 2 children', quantity: 100 };
            supportAnOrphanProductsList[2] = { ...inner_item_product, alternativeName: 'Support 3 Children', quantity: 150 };
            supportAnOrphanProductsList[3] = { ...inner_item_product, alternativeName: 'Support 4 Children', quantity: 200 };
          }
        });
      });
    });

    orphanFeastProductsList = orphanFeastProductsList.filter(n => n);
    feedPalestinianOrphansProductsList = feedPalestinianOrphansProductsList.filter(n => n);
    emergencyOrphanSupportProductsList = emergencyOrphanSupportProductsList.filter(n => n);
    supportAnOrphanProductsList = supportAnOrphanProductsList.filter(n => n);

    setOrphanFeastProducts(orphanFeastProductsList);
    setFeedPalestinianOrphansProducts(feedPalestinianOrphansProductsList);
    setEmergencyOrphanSupportProducts(emergencyOrphanSupportProductsList);
    setSupportAnOrphanProductsList(supportAnOrphanProductsList);
  };

  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <Layout className="bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['orphans']['orphans']['title']}`}</title>
          <meta name="title" content={`${seo['orphans']['orphans']['title']}`} />
          <meta name="description" content={`${seo['orphans']['orphans']['description']}`} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-GEN-MOR-2023-01-119',
            label: 'Orphans Around The World',
            value: 105,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img
          src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : 'https://cdn.matwproject.org/images/banners/orphans-banner.jpg'}
          alt="banner"
          className="min-[501px]:hidden w-full"
        />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'MKD-GEN-MOR-2023-01-119',
                label: 'Orphans Around The World',
                value: 105,
              }}
            />
          </div>
        </section>
      </section>

      <div id="appeals" ref={appealsRef}>
        {/* <OrphanTabsSection
          orphanFeastProductsList={orphanFeastProducts}
          feedPalestinianOrphansProductsList={feedPalestinianOrphansProducts}
          emergencyOrphanSupportProductsList={emergencyOrphanSupportProducts}
          supportAnOrphanProductsList={supportAnOrphanProducts}
        /> */}
        <OrphanTabs
          orphanFeastProducts={orphanFeastProducts}
          emergencyOrphanSupportProducts={emergencyOrphanSupportProducts}
          feedPalestinianOrphansProducts={feedPalestinianOrphansProducts}
          supportAnOrphanProducts={supportAnOrphanProducts}
        />
      </div>

      <OurPromiseSection />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-10 mt-5 sm:mt-12" id="more-info" ref={moreInfoRef}>
          <div className="flex justify-center">
            <div className="text-left font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12">
              MATW has reached and cared for more than 50,000 orphans worldwide.
              <br />
              <span className="text-[#F60362] mr-2">Help us reach more.</span>
            </div>
          </div>
          <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
            <div className="text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium">
              Every single day, 5,700 children become orphaned. In times of conflict and disaster, children are the first to be affected - especially
              if they become orphaned. The reality of poverty is a daily burden for adults, imagine what it would be like for a child? Where would
              they sleep? What would they eat? Who would look after them if they were to fall ill? We have to help them.
              <br />
              <br />
              With no one to turn to and often with no place to go, vulnerable children and orphans need support. This is where we at MATW come in.
              YOU can help us transform the lives of orphans and vulnerable children all over the world. Your support enables us to provide them with
              a safe environment to grow, an education to give them hope and options for a brighter future, access to healthcare, nutritious meals and
              so much more.
              <br />
              <br />
              Help us save and support orphaned children today.
            </div>
          </div>
        </section>
        {/* scroll more start */}
        <section className="xl:px-20 mt-8" id="scroll-more-start" ref={startRef}>
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
            >
              <div className="leading-[50px] w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold">
                The Prophet PBUH said:
              </div>
              <p className="text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                “Whoever takes in an orphan among the Muslims to raise, to feed him and give him drink, Allah admits him into Paradise without a
                doubt, unless he has done a sin for which he is not forgiven.”
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <div className="basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[300px] min-[410px]:min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[1000px]">
              <iframe
                className="video pointer-events-none"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                frameBorder={0}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://www.youtube.com/embed/ciUzaL90cxA?controls=0&autoplay=1&loop=1&mute=1&playlist=ciUzaL90cxA`}
              ></iframe>
            </div>
          </div>
        </section>

        <section className="xl:px-20 sm:py-8">
          <div>
            <div className="flex justify-center pt-4">
              <div className="text-left font-brandingBold sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                MATW’s orphans around the world program.
              </div>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5">
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                At MATW, we believe that every child deserves access to life’s most basic necessities and a positive start in life. From our humble
                beginnings with Ali Banat to where we are today, MATW’s Orphan projects have always been a top priority in our plan to alleviate
                suffering around the world.
                <br />
                <br />
                We started in Togo by supporting one orphan and now we operate entire centres in Togo and Lebanon, home to more than 5,000 orphaned,
                children and their widowed mothers.
                <br />
                <br />
                We continue to provide support to orphans in seven countries including Togo, Lebanon, Palestine, Yemen, Syria, Bangladesh and Jordan.
              </p>
              <div>
                <h2 className="text-[#253B7E] my-5 text-2xl sm:text-[45px] sm:leading-[54px] font-bold">
                  We work toward their prolonged wellbeing.
                  <br />
                  Our support includes:
                </h2>
                <ul className="text-[#78716C] list-disc pl-3 leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                  <li>A nurturing environment.</li>
                  <li>Access to formal schooling.</li>
                  <li>Recreational activities.</li>
                  <li>Islamic education, including learning of the Quran.</li>
                  <li>Regular health checkups and medical attention.</li>
                  <li>Nutritious meals.</li>
                  <li>Basic necessities including clothing.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="xl:px-20 sm:py-8">
          <div>
            <div className="flex justify-center pt-4">
              <div className="text-left font-brandingBold sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                MATW’s orphans around the world program.
              </div>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5">
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                At MATW, we believe that every child deserves access to life’s most basic necessities and a positive start in life. From our humble
                beginnings with Ali Banat to where we are today, MATW’s Orphan projects have always been a top priority in our plan to alleviate
                suffering around the world.
                <br />
                <br />
                We started in Togo by supporting one orphan and now we operate entire centres in Togo and Lebanon, home to more than 5,000 orphaned,
                children and their widowed mothers.
                <br />
                <br />
                We continue to provide support to orphans in seven countries including Togo, Lebanon, Palestine, Yemen, Syria, Bangladesh and Jordan.
              </p>
              <div>
                <h2 className="text-[#253B7E] my-5 text-2xl sm:text-[45px] sm:leading-[54px] font-bold">
                  We work toward their prolonged wellbeing.
                  <br />
                  Our support includes:
                </h2>
                <ul className="text-[#78716C] list-disc pl-3 leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                  <li>A nurturing environment.</li>
                  <li>Access to formal schooling.</li>
                  <li>Recreational activities.</li>
                  <li>Islamic education, including learning of the Quran.</li>
                  <li>Regular health checkups and medical attention.</li>
                  <li>Nutritious meals.</li>
                  <li>Basic necessities including clothing.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="px-4 xl:px-20 bg-white" id="faqs" ref={faqsRef}>
          <h1 className="text-[#253B7E] text-center my-4 text-[30px] sm:text-[60px] font-bold">
            MATW donations <span className="text-[#F60362]"> FAQ’s</span>
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[0].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[0].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[1].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[1].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[2].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[2].answer}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {orphansFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                      {item.answer}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </section>
        {/* Scroll more end */}
        <div id="scroll-more-end" ref={endRef}></div>
      </main>
      {showScrollMore && (
        <>
          <div
            className="fixed xs:bottom-12 md:bottom-0 left-1/2 transform -translate-x-1/2 w-full text-center text-[#14A2DC] text-[14px] font-medium py-2"
            style={{ zIndex: 999, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 100%)' }}
          >
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            scroll more
          </div>
        </>
      )}
      <BottomNav activeSection={activeSection} scrollToRef={scrollToRef} faqsRef={faqsRef} moreInfoRef={moreInfoRef} appealsRef={appealsRef} />
    </Layout>
  );
};

export default OrphansUat;
