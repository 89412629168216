const projectNoorFaqs = [
  {
    id: 0,
    question: 'What is Project Noor and its mission?',
    answer: `Project Noor is a humanitarian initiative under the <a class='text-[#00a3da]' href='/'>MATW Project</a>
                that focuses on providing sight-saving interventions and eye care services to underprivileged communities worldwide.
                Its mission is to eradicate preventable blindness, restore vision, and promote eye health through medical treatments,
                surgeries, and preventive education.`,
  },
  {
    id: 1,
    question: 'What are different donation options available?',
    answer: `Project Noor offers various donation options to cater to different preferences and budgets.
                You can make a <strong>One-time</strong> or <strong>Weekly</strong>, <strong>Monthly</strong>, <strong>Yearly</strong> basis donation
                to provide sustained assistance and support our initiatives.`,
  },
  {
    id: 2,
    question: 'Who does the initiative aim to help?',
    answer: `Project Noor aims to help individuals who lack access to quality eye care services,
                particularly those in economically disadvantaged communities.
                The initiative focuses on addressing a range of eye conditions,
                from cataracts to refractive errors, ensuring that people of all ages can benefit from improved vision and eye health.`,
  },
  {
    id: 3,
    question: 'What Methods of Payment Are Accepted?',
    answer: `We accept multiple payment methods to make the donation process convenient for supporters.
                You can use <strong>Credit Cards</strong> or <strong>Debit Cards</strong> from major providers, as well as online payment
                platforms such as <strong>Gpay</strong>, <strong>PayPal</strong>, and <strong>Apple Pay</strong> that are secure and trusted.
                The MATW Project ensures that your financial information remains confidential during the donation process.`,
  },
  {
    id: 4,
    question: 'How does Project Noor work?',
    answer: `Our charity partners with local medical professionals and organizations to identify individuals needing eye care services.
                Donations fund eye examinations, surgeries, medications, and eyeglasses.
                These interventions address a range of eye conditions, from refractive errors to cataracts and more.`,
  },
  {
    id: 5,
    question: 'Why is eye care critical?',
    answer: `Eye care is crucial as it enables people to maintain clear vision, which directly impacts their quality of life.
                Visual impairments can hinder education, employment opportunities, and overall well-being.
                By supporting Project Noor, you empower individuals to lead more fulfilling lives.`,
  },
  {
    id: 6,
    question: 'What types of eye conditions are treated?',
    answer: `The Eye Charity addresses a variety of conditions, including refractive errors (nearsightedness, farsightedness),
                cataracts, glaucoma, and more. The focus is on preventive measures and treatment options to restore and improve vision.`,
  },
  {
    id: 7,
    question: `Can I donate in someone else's name?`,
    answer: `Absolutely, you can donate in honor of someone else. This can be an excellent way to celebrate a special occasion or
                remember a loved one. The honoree will be notified of your generous gift.`,
  },
  {
    id: 8,
    question: 'What happens after I make a donation?',
    answer: `After making a donation, you will receive a confirmation and a receipt for your contribution.
                The MATW Project will ensure that your donation is used effectively to provide eye care services to those in need.`,
  },
  {
    id: 9,
    question: `How can I stay updated on the Project Noor eye charity's work?`,
    answer: `To stay informed about Project Noor's progress, impact, and stories,
                <strong>subscribe to the MATW Project's newsletter</strong> and follow our
                <a class='text-[#00a3da]' href='/social-media'>social media channels</a>.
                These platforms provide regular updates on our initiatives.`,
  },
  {
    id: 10,
    question: 'How can I contact the MATW Project for further inquiries?',
    answer: `If you have more questions or need additional information, you can reach out to us via the
                <a class='text-[#00a3da]' href='/contact'>contact page</a> or the provided contact details on the website.`,
  },
  {
    id: 11,
    question: 'Can I make a recurring donation to Project Noor eye charity?',
    answer: `Yes, recurring donations are welcome and greatly appreciated. Setting up a recurring donation allows
                you to contribute regularly, such as weekly, monthly & yearly, providing sustained support to Project Noor's initiatives.`,
  },
  {
    id: 12,
    question: 'Are there specific regions or countries where Project Noor operates?',
    answer: `The MATW Project eye charity aims to reach underprivileged communities worldwide.
                While the specific regions may vary based on current initiatives,
                the goal is to expand the reach of eye care services to as many vulnerable populations as possible.`,
  },
  {
    id: 13,
    question: 'How does the Project Noor eye charity ensure long-term sustainability?',
    answer: `The MATW Project is dedicated to creating sustainable impact. We work on building solid partnerships,
                focusing on community involvement, and implementing initiatives that have lasting benefits, ensuring the continuity of our efforts.`,
  },
];
const buildWaterWellFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact
        and continues to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief,
        Sadaqah Jariyah is considered an investment in good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `
        * 		Providing Clean Water: Donating to build or maintain wells, water pumps, or water purification systems that provide clean and accessible water
        to communities in need is one of the most highly recommended forms of Sadaqah Jariyah.
        <br/>
        * 		Supporting Education: Funding the construction and maintenance of schools, libraries, or educational institutions, as well as providing
        scholarships or educational resources to underprivileged students, ensures that knowledge continues to be accessible.
        <br/>
        * 		Planting Trees: Planting trees and contributing to reforestation efforts not only benefits the environment but also provides ongoing
        sustenance for humans and animals, making it a form of continuous charity.
        <br/>
        * 		Supporting Orphanages: Establishing or assisting orphanages and homes for vulnerable children provides them with shelter, education, and support,
        offering ongoing benefits for generations.
        <br/>
        * 		Building or Renovating Mosques: Contributing to the construction, maintenance, or renovation of mosques allows for the worship of Allah
        and the dissemination of Islamic knowledge, which continues indefinitely.
        <br/>
        * 		Sponsoring Islamic Literature: Donating funds to print and distribute Qurans, Islamic books, and educational materials can provide ongoing
        rewards as people continue to benefit from the knowledge contained in these resources.
        <br/>
        * 		Supporting Healthcare: Donating to hospitals, clinics, or medical programs that provide free or affordable healthcare to those in need can
        be a form of Sadaqah Jariyah.
        <br/>
        * 		Feeding the Hungry: Establishing or contributing to soup kitchens or food distribution programs ensures that the hungry are fed continuously.
        <br/>
        * 		Supporting the Elderly: Establishing care centers or providing ongoing assistance to elderly individuals who may not have family support can
        <br/>
        * be a form of ongoing charity.
        The best form of Sadaqah Jariyah ultimately depends on your personal circumstances, intentions, and the needs of the community or individuals you
        aim to support. It's essential to have a sincere intention (niyyah) and to seek Allah's pleasure in your charitable acts.
        Consulting with knowledgeable scholars or leaders within your local Muslim community can also provide guidance on the most effective ways to engage
        in ongoing charity."`,
  },
  {
    id: 2,
    question: 'Will I receive any reports for my donation?',
    answer: `If you choose to donate toward the construction of an entire water well, you can expect to receive a comprehensive report upon the completion of the well.
    Moreover, we will provide you with progress reports at various stages of the construction process.
    Our dedicated Donor Care team will maintain ongoing communication with you, ensuring that you are kept informed of developments and addressing any
    inquiries or concerns you may have along the way.`,
  },
  {
    id: 3,
    question: 'How long will my project take to build?',
    answer: `We kindly ask for your understanding that all our construction projects are granted a full year for completion. This extended timeframe allows us
    to meticulously progress through multiple essential stages before commencing construction. These stages include conducting a thorough needs assessment,
    which helps us identify crucial factors such as the location, size, and conditions of the community, as well as any challenges we may encounter,
    especially in remote forested areas.`,
  },
  {
    id: 4,
    question: 'Why do water projects cost different amounts?',
    answer: `The cost of constructing water wells can vary significantly based on several factors. One major determinant is the depth required to access water.
    In regions where the water is relatively shallow, typically up to around 30 meters, the installation tends to be less expensive. Moreover, soft ground conditions
    that do not necessitate heavy drilling equipment contribute to lower costs.
    <br />
    Conversely, areas with rocky terrain or greater depth requirements to access water will incur higher expenses. Unfortunately, remote areas,
    despite having the greatest need for water, often face neglect due to the elevated costs associated with installing a water well in these locations.`,
  },
  {
    id: 5,
    question: `Can I name the Water Well I've donated to build?`,
    answer: `We extend an invitation to donors to designate a name for their project contribution. Many donors opt to honor a lost loved one, themselves,
    or a family member with this naming privilege. Additionally, some donors may prefer to keep their charitable acts anonymous. The plaque provided upon project
    completion can bear either a name or a prayer, but it will consistently feature the Donation ID that you receive upon making your generous contribution.`,
  },
  {
    id: 6,
    question: 'What should I donate towards if I cannot choose?',
    answer: `When you are uncertain about where to allocate your donation, it is often most impactful to contribute to our 'Most Needed Funds.'
    These funds are typically directed towards critical and urgent projects. Additionally, you can explore a diverse array of projects on our website to
    discover one that aligns with your specific donation preferences and criteria.`,
  },
];
const masjidsFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact
        and continues to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief,
        Sadaqah Jariyah is considered an investment in good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `
    * 		Providing Clean Water: Donating to build or maintain wells, water pumps, or water purification systems that provide clean and accessible water to communities in need is one of the most highly recommended forms of Sadaqah Jariyah.
    <br />
    * 		Supporting Education: Funding the construction and maintenance of schools, libraries, or educational institutions, as well as providing scholarships or educational resources to underprivileged students, ensures that knowledge continues to be accessible.
    <br />
    * 		Planting Trees: Planting trees and contributing to reforestation efforts not only benefits the environment but also provides ongoing sustenance for humans and animals, making it a form of continuous charity.
    <br />
    * 		Supporting Orphanages: Establishing or assisting orphanages and homes for vulnerable children provides them with shelter, education, and support, offering ongoing benefits for generations.
    <br />
    * 		Building or Renovating Mosques: Contributing to the construction, maintenance, or renovation of mosques allows for the worship of Allah and the dissemination of Islamic knowledge, which continues indefinitely.
    <br />
    * 		Sponsoring Islamic Literature: Donating funds to print and distribute Qurans, Islamic books, and educational materials can provide ongoing rewards as people continue to benefit from the knowledge contained in these resources.
    <br />
    * 		Supporting Healthcare: Donating to hospitals, clinics, or medical programs that provide free or affordable healthcare to those in need can be a form of Sadaqah Jariyah.
    <br />
    * 		Feeding the Hungry: Establishing or contributing to soup kitchens or food distribution programs ensures that the hungry are fed continuously.
    <br />
    * 		Supporting the Elderly: Establishing care centers or providing ongoing assistance to elderly individuals who may not have family support can be a form of ongoing charity.
    The best form of Sadaqah Jariyah ultimately depends on your personal circumstances, intentions, and the needs of the community or individuals you aim to support. It's essential to have a sincere intention (niyyah) and to seek Allah's pleasure in your charitable acts. Consulting with knowledgeable scholars or leaders within your local Muslim community can also provide guidance on the most effective ways to engage in ongoing charity.`,
  },
  {
    id: 2,
    question: 'Will I receive any reports for my donation?',
    answer: `If you choose to donate toward the construction of an entire mosque, you can expect to receive a comprehensive report upon the completion of the mosque. Moreover, we will provide you with progress reports at various stages of the construction process. Our dedicated Donor Care team will maintain ongoing communication with you, ensuring that you are kept informed of developments and addressing any inquiries or concerns you may have along the way.`,
  },
  {
    id: 3,
    question: 'How long will my project take to build?',
    answer: `We kindly ask for your understanding that all our construction projects are granted a full year for completion. This extended timeframe allows us to meticulously progress through multiple essential stages before commencing construction. These stages include conducting a thorough needs assessment, which helps us identify crucial factors such as the location, size, and conditions of the community, as well as any challenges we may encounter, especially in remote forested areas.`,
  },
  {
    id: 4,
    question: 'Why are some masjids larger but cost less?',
    answer: `It's important to note that the cost and scale of construction projects can fluctuate significantly. Factors such as the prices of materials and labor in different regions can lead to variations in project costs. Additionally, harder-to-reach areas may incur higher expenses due to the challenges of transporting materials and labourers. However, the level of need in these remote regions can vary as well, depending on the results of our thorough needs assessment.`,
  },
  {
    id: 5,
    question: `Can I name the Masjid I've donated to build?`,
    answer: `We extend an invitation to donors to designate a name for their project contribution. Many donors opt to honor a lost loved one, themselves,
    or a family member with this naming privilege. Additionally, some donors may prefer to keep their charitable acts anonymous.
    The plaque provided upon project completion can bear either a name or a prayer, but it will consistently feature the Donation ID that you receive upon
    making your generous contribution.`,
  },
  {
    id: 6,
    question: 'What should I donate towards if I cannot choose?',
    answer: `When you are uncertain about where to allocate your donation, it is often most impactful to contribute to our 'Most Needed Funds.'
    These funds are typically directed towards critical and urgent projects. Additionally, you can explore a diverse array of projects on our website to
    discover one that aligns with your specific donation preferences and criteria.`,
  },
];
const sustainableFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact
        and continues to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief,
        Sadaqah Jariyah is considered an investment in good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `
    * 		Providing Clean Water: Donating to build or maintain wells, water pumps, or water purification systems that provide clean and accessible water to communities in need is one of the most highly recommended forms of Sadaqah Jariyah.
    <br />
    * 		Supporting Education: Funding the construction and maintenance of schools, libraries, or educational institutions, as well as providing scholarships or educational resources to underprivileged students, ensures that knowledge continues to be accessible.
    <br />
    * 		Planting Trees: Planting trees and contributing to reforestation efforts not only benefits the environment but also provides ongoing sustenance for humans and animals, making it a form of continuous charity.
    <br />
    * 		Supporting Orphanages: Establishing or assisting orphanages and homes for vulnerable children provides them with shelter, education, and support, offering ongoing benefits for generations.
    <br />
    * 		Building or Renovating Mosques: Contributing to the construction, maintenance, or renovation of mosques allows for the worship of Allah and the dissemination of Islamic knowledge, which continues indefinitely.
    <br />
    * 		Sponsoring Islamic Literature: Donating funds to print and distribute Qurans, Islamic books, and educational materials can provide ongoing rewards as people continue to benefit from the knowledge contained in these resources.
    <br />
    * 		Supporting Healthcare: Donating to hospitals, clinics, or medical programs that provide free or affordable healthcare to those in need can be a form of Sadaqah Jariyah.
    <br />
    * 		Feeding the Hungry: Establishing or contributing to soup kitchens or food distribution programs ensures that the hungry are fed continuously.
    <br />
    * 		Supporting the Elderly: Establishing care centers or providing ongoing assistance to elderly individuals who may not have family support can be a form of ongoing charity.
    The best form of Sadaqah Jariyah ultimately depends on your personal circumstances, intentions, and the needs of the community or individuals you aim to support. It's essential to have a sincere intention (niyyah) and to seek Allah's pleasure in your charitable acts. Consulting with knowledgeable scholars or leaders within your local Muslim community can also provide guidance on the most effective ways to engage in ongoing charity.`,
  },
  {
    id: 2,
    question: 'What makes a project or my donation sustainable?',
    answer: `A sustainable project is one that is designed, implemented, and managed in a way that meets present needs without compromising the ability of
    future generations to meet their own needs. Sustainability encompasses economic, environmental, and social considerations to ensure that the project's
    benefits endure over the long term.`,
  },
  {
    id: 3,
    question: 'Will I receive any reports for my donation?',
    answer: `Sustainable projects will be featured in our annual Impact Report, providing a comprehensive overview of their progress and achievements
    within the preceding year.`,
  },
  {
    id: 4,
    question: 'What should I donate towards if I cannot choose?',
    answer: `When you are uncertain about where to allocate your donation, it is often most impactful to contribute to our 'Most Needed Funds.'
    These funds are typically directed towards critical and urgent projects. Additionally, you can explore a diverse array of projects on our website to
    discover one that aligns with your specific donation preferences and criteria.`,
  },
];
const emergencyFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact and continues
      to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief, Sadaqah Jariyah is considered an investment in
      good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `
      * 		Providing Clean Water: Donating to build or maintain wells, water pumps, or water purification systems that provide clean and accessible water to communities in need is one of the most highly recommended forms of Sadaqah Jariyah.
      <br />
      * 		Supporting Education: Funding the construction and maintenance of schools, libraries, or educational institutions, as well as providing scholarships or educational resources to underprivileged students, ensures that knowledge continues to be accessible.
      <br />
      * 		Planting Trees: Planting trees and contributing to reforestation efforts not only benefits the environment but also provides ongoing sustenance for humans and animals, making it a form of continuous charity.
      <br />
      * 		Supporting Orphanages: Establishing or assisting orphanages and homes for vulnerable children provides them with shelter, education, and support, offering ongoing benefits for generations.
      <br />
      * 		Building or Renovating Mosques: Contributing to the construction, maintenance, or renovation of mosques allows for the worship of Allah and the dissemination of Islamic knowledge, which continues indefinitely.
      <br />
      * 		Sponsoring Islamic Literature: Donating funds to print and distribute Qurans, Islamic books, and educational materials can provide ongoing rewards as people continue to benefit from the knowledge contained in these resources.
      <br />
      * 		Supporting Healthcare: Donating to hospitals, clinics, or medical programs that provide free or affordable healthcare to those in need can be a form of Sadaqah Jariyah.
      <br />
      * 		Feeding the Hungry: Establishing or contributing to soup kitchens or food distribution programs ensures that the hungry are fed continuously.
      <br />
      * 		Supporting the Elderly: Establishing care centers or providing ongoing assistance to elderly individuals who may not have family support can be a form of ongoing charity.
      The best form of Sadaqah Jariyah ultimately depends on your personal circumstances, intentions, and the needs of the community or individuals you aim to support. It's essential to have a sincere intention (niyyah) and to seek Allah's pleasure in your charitable acts. Consulting with knowledgeable scholars or leaders within your local Muslim community can also provide guidance on the most effective ways to engage in ongoing charity.`,
  },
  {
    id: 2,
    question: 'Will I receive any reports for my donation?',
    answer: `We are committed to transparency and timely communication with our donors. Once a project has been fully implemented, we will promptly share
      Impact Reports with donors. In addition to these comprehensive reports, we provide regular updates on the ongoing progress of our projects,
      which you can follow through our social media platforms with weekly updates.
      <br/>
      For projects with a more personalised touch, such as a Caravan home, we go the extra mile. Donors can expect to receive a report complete with images and media,
      GPS location data as proof of delivery, and a personalised plaque affixed to the caravan with the name of your choice, ensuring that your contribution is
      celebrated and recognised in a meaningful way.`,
  },
  {
    id: 3,
    question: 'How long will my project take to build?',
    answer: `If your donation was designated for a caravan, you can anticipate that the implementation process will typically be completed within a month, ensuring a swift impact. However, for donations intended for other emergency relief products, please be aware that we regularly allocate funds to the field to support ongoing emergency work. Consequently, these funds are likely to be exhausted within a week as we respond rapidly to critical needs.`,
  },
  {
    id: 4,
    question: 'What should I donate towards if I cannot choose?',
    answer: `When you are uncertain about where to allocate your donation, it is often most impactful to contribute to our 'Most Needed Funds.' These funds are typically directed towards critical and urgent projects. Additionally, you can explore a diverse array of projects on our website to discover one that aligns with your specific donation preferences and criteria.`,
  },
];
const sadaqahJariyahFaqs = [
  {
    id: 0,
    question: 'What is Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah is a concept in Islamic philanthropy and charity. It refers to a continuous or ongoing charity that has a lasting impact and continues to benefit people or the community over time. Unlike regular forms of charity that provide immediate relief, Sadaqah Jariyah is considered an investment in good deeds that keeps giving rewards in this life and the hereafter.`,
  },
  {
    id: 1,
    question: 'What is the best Sadaqah Jariyah?',
    answer: `
    * 		Providing Clean Water: Donating to build or maintain wells, water pumps, or water purification systems that provide clean and accessible water to communities in need is one of the most highly recommended forms of Sadaqah Jariyah.
    <br />
    * 		Supporting Education: Funding the construction and maintenance of schools, libraries, or educational institutions, as well as providing scholarships or educational resources to underprivileged students, ensures that knowledge continues to be accessible.
    <br />
    * 		Planting Trees: Planting trees and contributing to reforestation efforts not only benefits the environment but also provides ongoing sustenance for humans and animals, making it a form of continuous charity.
    <br />
    * 		Supporting Orphanages: Establishing or assisting orphanages and homes for vulnerable children provides them with shelter, education, and support, offering ongoing benefits for generations.
    <br />
    * 		Building or Renovating Mosques: Contributing to the construction, maintenance, or renovation of mosques allows for the worship of Allah and the dissemination of Islamic knowledge, which continues indefinitely.
    <br />
    * 		Sponsoring Islamic Literature: Donating funds to print and distribute Qurans, Islamic books, and educational materials can provide ongoing rewards as people continue to benefit from the knowledge contained in these resources.
    <br />
    * 		Supporting Healthcare: Donating to hospitals, clinics, or medical programs that provide free or affordable healthcare to those in need can be a form of Sadaqah Jariyah.
    <br />
    * 		Feeding the Hungry: Establishing or contributing to soup kitchens or food distribution programs ensures that the hungry are fed continuously.
    <br />
    * 		Supporting the Elderly: Establishing care centers or providing ongoing assistance to elderly individuals who may not have family support can be a form of ongoing charity.
    The best form of Sadaqah Jariyah ultimately depends on your personal circumstances, intentions, and the needs of the community or individuals you aim to support. It's essential to have a sincere intention (niyyah) and to seek Allah's pleasure in your charitable acts. Consulting with knowledgeable scholars or leaders within your local Muslim community can also provide guidance on the most effective ways to engage in ongoing charity.`,
  },
  {
    id: 2,
    question: 'Will I receive any reports for my donation?',
    answer: `Your contribution will be directed to our Sadaqah Jariyah Fund, where it will be allocated based on the prevailing needs. This may involve the construction of water wells in areas with the greatest need, the maintenance of mosques and water wells, or enhancements to our orphan care centers. Our team will provide periodic impact reports to keep our donors informed about how their contributions are making a difference.`,
  },
  {
    id: 3,
    question: 'Are any of these projects applicable for Zakat?',
    answer: `Please note that some of our projects may qualify for Zakat, while others may not meet the criteria. If you intend to fulfill your Zakat obligation, we recommend directing your donation specifically to our Zakat projects, ensuring that your contribution is allocated appropriately.`,
  },
  {
    id: 4,
    question: 'What should I donate towards if I cannot choose?',
    answer: `When you are uncertain about where to allocate your donation, it is often most impactful to contribute to our 'Most Needed Funds.' These funds are typically directed towards critical and urgent projects. Additionally, you can explore a diverse array of projects on our website to discover one that aligns with your specific donation preferences and criteria.`,
  },
];
const sadaqaFaqs = [
  {
    id: 0,
    question: 'Why is Sadaqah Jariyah important in Islam?',
    answer: `Sadaqah Jariyah is highly regarded in Islam because it represents a way to earn continuous rewards even after one's lifetime. It is seen as a means to attain spiritual growth, purification, and a means of helping others while strengthening one's connection with Allah.
    `,
  },
  {
    id: 1,
    question: 'What are the benefits of Sadaqah Jariyah?',
    answer: `The benefits of Sadaqah Jariyah extend to both the giver and the recipient. It includes earning ongoing rewards in the hereafter, purification of wealth, supporting important community needs, and leaving a lasting legacy of goodwill and charity.`,
  },
  {
    id: 2,
    question: 'What is the difference between Sadaqah and Sadaqah Jariyah?',
    answer: `Sadaqah refers to regular acts of charity, which provide immediate relief to those in need. Sadaqah Jariyah, on the other hand, offers perpetual benefits and continues to help people long-term, even after the donor's passing.`,
  },
  {
    id: 3,
    question: 'Why do Muslims give Sadaqah Jariyah?',
    answer: `Muslims give Sadaqah Jariyah to fulfill their religious duty of ongoing charity, earn eternal rewards, purify their wealth, and leave a lasting positive impact on society by supporting important causes.`,
  },
  {
    id: 4,
    question: 'Examples of Sadaqah Jariyah:',
    answer: `Examples of Sadaqah Jariyah include building mosques, digging wells, funding educational initiatives, supporting healthcare facilities, planting trees, and contributing to projects that provide long-term benefits to communities.`,
  },
  {
    id: 5,
    question: 'How MATW Project uses your Sadaqah Jariyah donations',
    answer: `MATW Project channels Sadaqah Jariyah donations into various initiatives, such as constructing and maintaining mosques, providing clean water access, supporting educational programs, improving healthcare facilities, and caring for orphans. These efforts ensure that the ongoing charity continues to benefit communities in need.`,
  },
  {
    id: 6,
    question: 'When should you give Sadaqah Jariyah?',
    answer: `Sadaqah Jariyah can be given at any time, but many Muslims choose to make these donations during significant life events or in times of personal reflection. It's a way to make a lasting impact and secure ongoing rewards in the hereafter, regardless of the timing.`,
  },
  {
    id: 7,
    question: 'What makes Sadaqah Jariyah different from regular charity?',
    answer: `Sadaqah Jariyah is a form of ongoing charity that continues to benefit recipients long after the initial donation. Regular charity helps immediately, while Sadaqah Jariyah ensures perpetual rewards.`,
  },
  {
    id: 8,
    question: 'What is the best Sadaqah Jariyah in Islam?',
    answer: `The best Sadaqah Jariyah in Islam is a charitable act that continues to benefit people long after the initial donation. This could be projects like those mentioned above, which contribute to the betterment of society and fulfill ongoing needs.`,
  },
  {
    id: 9,
    question: 'What is the difference between Zakat and Sadaqah Jariyah?',
    answer: `Zakat: It is obligatory, a fixed percentage (usually 2.5%) of wealth, given annually, aimed at immediate relief for specific categories of recipients (e.g., the poor, and needy).
    Sadaqah Jariyah: It is voluntary, ongoing, provides perpetual benefits, is directed towards sustainable community projects (e.g., building mosques, clean water sources, education), and is not mandatory but highly encouraged.`,
  },
  {
    id: 10,
    question: 'Can I give Sadaqah Jariyah on behalf of someone else?',
    answer: `Yes, you can give Sadaqah Jariyah on behalf of others as a virtuous act, whether for the deceased or the living, to continue their good deeds.`,
  },
];
const aqiqahFaqs = [
  {
    id: 0,
    question: 'What is the difference between Nidr (general sacrifice) and Aqeeqah?',
    answer: `The general sacrifice is given for any reason whereas the Aqeeqah is given to commemorate the arrival of a newborn whether it be boy or girl.`,
  },
  {
    id: 1,
    question: 'How does Aqeeqah or General Sacrifice help?',
    answer: `Your sacrifice is delivered within impoverished communities, serving the most vulnerable including families with widows, orphans and the elderly. In these communities, meat is a rare treat that isn’t often enjoyed as part of a daily diet. Your Nidr or Aqeeqah not only offers food but nourishment.`,
  },
  {
    id: 2,
    question: 'How often does MATW perform Nidr/ Aqeeqah?',
    answer: `This program is often year round.`,
  },
];
const muharramFaqs = [
  {
    id: 0,
    question: 'WHEN IS MUHARRAM? ',
    answer:
      'Muharram is the first month of the Hijri calendar and thus marks the beginning of the Islamic Calendar year. It is the month known to be the sacred month of Allah, thus making it a highly blessed month. This year, Muharram begins on the 7th July 2024. ',
  },
  {
    id: 1,
    question: 'HOW SHOULD ONE COMMEMORATE MUHARRAM?',
    answer:
      "There are many virtues of the month of Muharram and many things we can do to take advantage of the benefits of the sacred month of Allah.  These are some of the recommended actions that are Sunnah during this blessed month: " ,
    part: [
      'Make more Dua and read more Quran: If  you’re unable to fast, read some Quran and increase your duas.',
      'Give regular Sadaqah: The beginning of the Islamic new year is the perfect time to establish good habits with more good deeds such as ones that help us attain more reward and blessing. Give to increase your rewards during this blessed month.',
      "Fasting: Fast on the 9th and 10th of Muharram (the day of Ashura) or on the 10th and 11th of Muharram.'"
    ],
  },
  {
    id: 2,
    question: 'WHEN IS ASHURA? ',
    answer:
      'The day of Ashura falls on the 10th day of Muharram. This year, the day of Ashura is expected to fall on the 16th July 2024 but ultimately depends on the sighting of the moon.',
  },
];
export { projectNoorFaqs, buildWaterWellFaqs, masjidsFaqs, sustainableFaqs, emergencyFaqs, sadaqahJariyahFaqs, sadaqaFaqs, aqiqahFaqs, muharramFaqs };
