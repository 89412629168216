const feastFaqs = [
  {
    id: 0,
    question: 'How do food donations support orphans?',
    answer: `Donating food helps support orphans by giving them nutritious meals.
    For the orphans in their care to have access to food, orphanages need a consistent supply of food.
    Food donations help to ensure that the orphanages have the necessary supplies to provide meals to the orphans.`,
  },
  {
    id: 1,
    question: 'Why is it significant in Islam to feed orphans?',
    answer: `In Islam, feeding orphans is significant since it is regarded as both a basic requirement and a way to carry out the obligation to aid the poor.
    Additionally, feeding orphans gives the children who have lost their parents comfort and security.`,
  },
  {
    id: 2,
    question: 'Are there any specific food items that should be donated to orphanages?',
    answer: `When contributing food to orphanages, it's crucial to include wholesome, nutritional foods that can give the kids a balanced diet.
    The best donations are typically non-perishable items, dry products, and canned food.`,
  },
  {
    id: 3,
    question: 'What are some ways I can help MATW provide food for Muslim orphans?',
    answer: `Muslim orphan food charities can be supported through donations, volunteering, and spreading awareness about their work.
    Many of these charities have specific programs that allow donors to sponsor meals for orphans on a monthly or yearly basis.`,
  },
  {
    id: 4,
    question: 'How do orphan food aid programs differ from other food aid programs?',
    answer: `Orphan food aid programs specifically target orphaned children, who are often among the most vulnerable and in need of support.
    These programs focus on providing regular meals to orphaned children, as opposed to providing emergency food aid in response to a crisis or disaster.`,
  },
  {
    id: 5,
    question: 'Can orphan food aid programs help address malnutrition among orphaned children?',
    answer: `Yes, orphan food aid programs can help address malnutrition among orphaned children
    by providing them with regular access to nutritious and balanced meals.
    This can help improve their overall health and well-being.`,
  },
  {
    id: 6,
    question: 'How can I support an orphan food aid program?',
    answer: `You can help an organization that implements orphan food aid programs by volunteering with them,
    making a donation to a reputable charity such as MATW, that helps orphans with their food needs,
    or organizing a food drive to raise money for a charity or orphanage.`,
  },
];
const supportFaqs = [
  {
    id: 0,
    question: 'What is orphan support?',
    answer: `Support and care for children who have lost one or both of their parents is referred to as orphan support.
    Support for orphans may take the form of housing, food, instruction, medical treatment, and emotional support.`,
  },
  {
    id: 1,
    question: 'How can I support an orphan through MATW Project?',
    answer: `You can support an orphan through MATW Project by donating to one of our support programs.
    The support programs cover the cost of food, shelter, education, and healthcare for the orphan for a year, three months, or one month.`,
  },
  {
    id: 2,
    question: 'Can I choose the country where I want to support an orphan through MATW Project?',
    answer: `Yes, you can choose to support an orphan in Togo, Bangladesh, Lebanon, Jordan, Palestine, or Yemen.`,
  },
  {
    id: 3,
    question: 'How does MATW Project ensure the well-being of orphans?',
    answer: `MATW Project's highly qualified staff and volunteers put through countless hours to make sure the orphans get the finest care possible.
    The orphanage homes are furnished with all the amenities required to ensure the children's welfare and growth.`,
  },
  {
    id: 4,
    question: 'How is the money donated to MATW Project used?',
    answer: `The money donated to MATW Project is used to support our various initiatives,
    including orphanage houses, orphanage maintenance, and support programs for orphans.
    The organization is committed to ensuring that the funds are used in a transparent and effective manner.`,
  },
  {
    id: 5,
    question: 'How can I make a donation to MATW Project?',
    answer: `You can make a donation to MATW Project through our website.
    The organization accepts donations through various payment methods, including credit card, PayPal, and bank transfer.`,
  },
  {
    id: 6,
    question: 'How can I get involved with MATW Project?',
    answer: `You can get involved with MATW Project by volunteering your time or skills.
    Volunteers who can assist with a variety of duties, such as fundraising, marketing, and event preparation, are constantly needed by the organization.
    Through social media and word-of-mouth, you can also spread the word about the company and its initiatives.`,
  },
  {
    id: 7,
    question: 'Can I sponsor an orphan through MATW Project?',
    answer: `Yes, you can sponsor an orphan through MATW Project by donating to one of their support programs.
    The support programs cover the cost of food, shelter, education, and healthcare for the orphan for a year, three months, or one month.`,
  },
  {
    id: 8,
    question: 'How much does it cost to support an orphan through MATW Project?',
    answer: `MATW Project orphan support fees vary by nation and term of support, as well as other factors.
    You can visit the organization's website for more information on the cost of each support program.`,
  },
  {
    id: 9,
    question: 'How long does it take for my donation to reach an orphan?',
    answer: `The MATW Project works to ensure that your donation reaches the orphan as soon as possible.
    The organization has efficient systems in place to ensure that the funds are transferred to the orphanage houses and used to support the orphans.`,
  },
  {
    id: 10,
    question: 'How can I track the progress of the orphan I am supporting through MATW Project?',
    answer: `The MATW Project provides regular updates on the progress of the orphans through their website and social media channels.
    You can also contact the organization directly for more information on the orphan you are supporting.`,
  },
  {
    id: 11,
    question: 'Can I support more than one orphan through MATW Project?',
    answer: `Yes, you can support multiple orphans through MATW Project by donating to multiple support programs or sponsoring multiple orphans.`,
  },
  {
    id: 12,
    question: 'What is an orphanage house?',
    answer: `An orphanage house is a facility that provides shelter and care for orphans who have lost one or both parents.
    The orphanage house provides a safe and nurturing environment for the orphans to grow and develop.`,
  },
  {
    id: 13,
    question: 'How does MATW Project ensure the safety and security of the orphans in the orphanage houses?',
    answer: `MATW takes the safety and security of orphans very seriously.
    The organization has strict policies and procedures in place to ensure that our orphans are protected from harm.
    The orphanage houses are equipped with security systems, and the staff and volunteers are carefully screened and trained.`,
  },
  {
    id: 14,
    question: 'Can I volunteer at an orphanage house supported by MATW Project?',
    answer: `Yes, you can volunteer at an orphanage house supported by MATW Project.
    The organization welcomes volunteers who can help with various tasks, including tutoring, mentoring, and organizing activities for the orphans.`,
  },
  {
    id: 15,
    question: 'What other support does MATW Project offer to orphans?',
    answer: `The MATW Project offers a wide range of support to orphans, including healthcare, counselling, and emotional support.
    The organization also works to reunite orphans with their extended families or place them with suitable caregivers.`,
  },
  {
    id: 16,
    question: 'How does MATW Project ensure the long-term well-being of orphans?',
    answer: `MATW Project has a comprehensive approach to ensuring the long-term well-being of orphans.
    The organization works to provide orphans with the necessary skills and resources to become self-sufficient adults.
    The organization also works to ensure that the orphans have a safe and stable home environment.`,
  }
];
const educationFaqs = [
  {
    id: 0,
    question: 'What kind of education do the orphans receive through MATW Project?',
    answer: `The orphans supported by MATW Project receive a quality education that is tailored to their needs and abilities.
    The organization works to ensure that orphans have access to the necessary resources and tools to succeed academically.`,
  },
  {
    id: 1,
    question: 'How does MATW Project support orphan education?',
    answer: `MATW Project supports orphan education by providing the necessary resources and infrastructure, such as school supplies, textbooks, and classrooms.
    The organization also hires qualified teachers and provides scholarships for higher education.`,
  },
  {
    id: 2,
    question: 'Can I donate specifically to support orphan education through MATW Project?',
    answer: `Yes, you can donate specifically to support orphan education through MATW Project.
    The organization has specific education-focused programs that you can donate to.`,
  },
  {
    id: 3,
    question: 'Why is supporting education for orphans important?',
    answer: `Supporting education for orphans is crucial because it provides them with the necessary knowledge and skills to improve their lives
    and become self-sufficient members of society. Education also empowers them to break the cycle of poverty, achieve their goals, and build a better future.
    Orphans who have access to education are more likely to secure employment and have a higher standard of living, improving their overall well-being.`,
  },
  {
    id: 4,
    question: `How does MATW Project support orphans' education?`,
    answer: `MATW Project supports orphans' education by providing financial assistance, school supplies,
    and mentorship to ensure orphans have the necessary resources to succeed.
    The charity covers the costs of tuition, transportation, and other educational expenses, ensuring that orphans have access to education.
    The mentorship programs offered by MATW Project provide orphans with guidance and support as they navigate their educational journey.`,
  },
  {
    id: 5,
    question: `What impact has MATW Project had on orphans' education?`,
    answer: `MATW Project has had a significant impact on the lives of orphans by providing education support to hundreds of children.
    The financial assistance provided by the charity has enabled orphans to attend school, which would otherwise have been impossible.
    The mentorship programs offered by MATW Project have helped orphans develop valuable skills and build their confidence,
    enabling them to overcome the challenges they face.
    The school supplies provided by the charity have also had a significant impact, ensuring that orphans have everything they need to succeed academically.`,
  }
];
const orphansFaqs=[
  {
    id: 0,
    question: 'What is orphan sponsorship and charity, exactly?',
    answer:`Orphan sponsorship and charity refers to charitable organizations and initiatives created to help and look after kids who have lost both of their parents.`
  },
  {
    id: 1,
    question: 'Why is orphan charity important?',
    answer:`Orphans are among the most vulnerable people in society and frequently don't receive the attention and support they need. Orphan charity is significant because it gives these kids the resources and assistance they need to succeed despite their challenging situations.`
  },
  {
    id: 2,
    question: 'What type of assistance do orphan charities offer?',
    answer:`Orphan organizations offer a wide range of assistance services, such as food and clothes, shelter, healthcare, and education.`
  },
  {
    id: 3,
    question: 'How do I donate to an orphanage?',
    answer:`You can donate by contributing to MATW’S orphan centre, sponsoring an orphan, or supporting with the many costs associated with running an orphanage.`
  },
  {
    id: 4,
    question: 'What does it mean to sponsor an orphan?',
    answer:`Sponsoring an orphan means committing to providing regular financial support to help cover the costs of their education, healthcare, and other basic needs. This type of support can be life-changing for a child who has lost their parents and is struggling to make ends meet.`
  },
  {
    id: 5,
    question: 'Can I volunteer with an orphan charity?',
    answer:`Yes, many orphan charities rely on volunteers to help with their programs and services. We at MATW do assist in volunteering opportunities.`
  },
  {
    id: 6,
    question: 'How else can I help orphans without making a donation?',
    answer:`In addition to making a financial contribution, you may support orphan charities by raising awareness of the issue, giving up your time, planning fundraising events, and speaking out in favour of laws that will benefit abandoned children.`
  },
  {
    id: 7,
    question: 'Are there any specific orphan charities that focus on Islamic orphans?',
    answer:`Yes, there are many orphan charities that focus specifically on supporting Islamic orphans. These charities are guided by the principles of compassion and generosity that are central to the Islamic faith. Our MATW’s orphan sponsorship project is one of the leading Islamic orphan charities.`
  },
  {
    id: 8,
    question: 'How can I make sure my donation to an orphan charity is being put to good use?',
    answer:`Before making a donation, do your research to ensure that the charity you are donating to has a good reputation and is using its resources effectively. Look for transparency and accountability in the charity's financial and operational practices.`
  },
  {
    id: 9,
    question: 'What are the benefits of giving to orphans?',
    answer:`Giving to orphans is considered a virtuous act in Islam, and it brings numerous benefits to both the giver and the receiver. It helps provide basic needs, education, and healthcare to children who are in desperate need of support. It also brings blessings and rewards in the afterlife for the giver, as it is considered a form of sadaqah or charity.`
  },
  {
    id: 10,
    question: 'What does Islam say about helping orphans?',
    answer:`Islam places a high value on helping orphans and views it as a noble deed. The Prophet Muhammad (peace be upon him) himself was an orphan and he stressed the importance of treating orphans with kindness and compassion. In Islam, it is considered a major sin to mistreat orphans or neglect their needs.`
  },
  {
    id: 11,
    question: 'How does one treat orphans in Islam?',
    answer:`Islam regards it as a religious duty to treat orphans with care and compassion. Muslims are encouraged to love, support, and care for orphans just as they would their own children. They should also ensure that orphans receive their rights and are not mistreated or neglected in any way.`
  },
  {
    id:12,
    question:'What is the reward for helping orphans in Islam?',
    answer:`Supporting orphans is viewed as a virtue in Islam and is associated with numerous benefits both in this world and the next. Muslims who help orphans are promised by Allah a large recompense, and their charitable deeds will be considered a source of good works on the Day of Judgment.`
  },
  {
    id:13,
    question:'Can you give sadaqah to orphans?',
    answer:`Yes, giving sadaqah or charity to orphans is highly encouraged in Islam. It is considered a virtuous act that brings blessings and rewards to both the giver and the receiver.`
  },
  {
    id:14,
    question:'What does Allah say about helping orphans?',
    answer:`In numerous verses of the Quran, Allah stresses the need of aiding orphans. According to Allah, "As for the orphan, do not oppress him, and as for the petitioner, do not repel him" (93:9–10) are two such verses. This verse emphasizes the significance of providing for the needs of orphans and treating them with respect and compassion.`
  },
  {
    id:15,
    question:'What is the best charity for orphans?',
    answer:`There are many reputable charities and organizations that focus on helping orphans. The best charity for orphans is one that has a good reputation, is transparent in its operations, and is aligned with your values and beliefs. Some popular orphan charities include Muslim Around the World.`
  }
  
]
export { feastFaqs, supportFaqs, educationFaqs ,orphansFaqs};
