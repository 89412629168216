import React from 'react'
import CustomAccordion from '../../../components/general/customAccordion';
import { foodFaqs, waterFaqs } from '../faqs';
// import CustomAccordion from '../../../../components/general/customAccordion';
// import { palestineFaqs, newPalestineFaqsArabic } from '../faqs';

const FAQSection = ({ arabic }) => {
  const RawHTML = ({children, className = ""}) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '')}} />
  return (
    <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={arabic ? 'rtl' : 'ltr'}>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
        <CustomAccordion>
          <CustomAccordion.Title tag="h2" className={arabic ? '!font-[AlmariMedium] font-bold' : ''}>
          Food Aid Donations <span className="text-[#F60362]"> FAQ’s</span>
          </CustomAccordion.Title>
          <CustomAccordion.Header>
            {foodFaqs &&
              foodFaqs.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion>{question}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer><RawHTML>{answer}</RawHTML></CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )}
          </CustomAccordion.Header>
          <div className={`mt-6`}>
            <CustomAccordion.Accordion items={foodFaqs.filter((_, index) => index > 2)} />
          </div>
        </CustomAccordion>
      </div>
      <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6 md:mt-12">
        <CustomAccordion>
          <CustomAccordion.Title tag="h2" className={arabic ? '!font-[AlmariMedium] font-bold' : ''}>
          Water Aid Donations <span className="text-[#F60362]"> FAQ’s</span>
          </CustomAccordion.Title>
          <CustomAccordion.Header>
            {waterFaqs &&
              waterFaqs.map(
                ({ question, answer }, index) =>
                  index < 3 && (
                    <CustomAccordion.HeaderContent key={index}>
                      <CustomAccordion.HeaderQuestion>{question}</CustomAccordion.HeaderQuestion>
                      <CustomAccordion.HeaderAnswer><RawHTML>{answer}</RawHTML></CustomAccordion.HeaderAnswer>
                    </CustomAccordion.HeaderContent>
                  ),
              )}
          </CustomAccordion.Header>
          <div className={`mt-6`}>
            <CustomAccordion.Accordion items={waterFaqs.filter((_, index) => index > 2)} />
          </div>
        </CustomAccordion>
      </div>
    </section>
  )
}

export default FAQSection;